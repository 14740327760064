import AboutUs1 from "../../assets/images/png/about-us.png";
import AboutUs2 from "../../assets/images/png/about-us1.png";
import {ReactComponent as Phone} from "../../assets/images/svg/call.svg";
import {ReactComponent as Mail} from "../../assets/images/svg/mail.svg";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import api from "../../service/api";
import usePages from "../../hooks/usePages";

function AboutUs() {
    const {t} = useTranslation();
    const {pageData} = usePages(1);
    const {setIsLoading} = useContext(UserContext);
    const [about, setAbout] = useState(null);
    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/about-us');
            setAbout(response.data);
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="about-us-container">

            <div className="about-us-top">

                <div className="about-us-left-side">
                    <div className="about-us-title">
                        <h4>{pageData?.page.title}</h4>
                    </div>
                    <div className="about-us-text" dangerouslySetInnerHTML={{__html: pageData?.page.content}}/>
                </div>

                <div className="about-us-right-side">
                    <div className="about-us-image">
                        <img src={AboutUs1} alt="about-us-img"/>
                    </div>
                    <div className="about-us-image-second">
                        <img src={AboutUs2} alt="about-us-img"/>
                    </div>
                </div>

            </div>

            <div className="about-us-structure">

                <div className="about-us-structure-cards">

                    {about?.administrativePersons.map((person, index) => (
                        <div
                            key={index}
                            className="about-us-structure-card"
                        >
                            <p>{person.name} {person.surname}</p>
                            <span className="about-us-position">
                            <p>{person.position}</p>
                            <p>{person.department}</p>
                            </span>
                            <div className="about-us-structure-contact">
                                <span>
                                    <a href={`tel:${person.phone}`}>
                                        <Phone/>{person.phone}
                                    </a>
                                </span>
                                <span>
                                    <a href={`mailto:${person.email}`}>
                                        <Mail/>{person.email}
                                    </a>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div className="about-us-values">
                <div className="about-us-values-cards">
                    {about?.values.filter(value => Boolean(value.description)).map((value, index) => (
                        <div
                            key={index}
                            className="about-us-values-card"
                        >
                            <div className="about-us-values-card-title">
                                <span>{value.name}</span>
                            </div>
                            <div className="about-us-values-card-text">
                                <p>
                                    {value.description}
                                </p>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AboutUs;

import React from 'react';
import {Button, Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useTranslation} from "react-i18next";

function ContactForm({onFinish, form, errors}) {
    const {t} = useTranslation();
    const onPhoneChange = (event) => {
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!Number.isNaN(event.key) && reg.test(event.key)) || event.key === '' || event.key === '-') {
            return true
        }
        event.preventDefault();
    };
    return (
        <>
            <div className="contact-right-side-title">
                <h2>{t("contactUs")}</h2>
            </div>
            <Form className="contact-right-side-form" layout="vertical" onFinish={onFinish} form={form}>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        },
                    ]}>
                    <Input
                        bordered={false}
                        placeholder={t("name") + "*"}
                    />
                </Form.Item>
                <Form.Item
                    name="surname"
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        },
                    ]}>
                    <Input
                        bordered={false}
                        placeholder={t("surname") + "*"}
                    />
                </Form.Item>
                <Form.Item
                    className="registration-input contact-input"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        },
                    ]}
                >
                    <Input
                        onKeyPress={onPhoneChange}
                        bordered={false}
                        placeholder={t("phoneNumber") + "*"}
                        controls={false}
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    validateStatus={errors?.email && ("error")}
                    help={errors?.email}
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        },
                    ]}
                >
                    <Input
                        bordered={false}
                        placeholder={t("email") + "*"}
                    />
                </Form.Item>

                <Form.Item
                    name="subject"
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        },
                    ]}>
                    <Input
                        bordered={false}
                        placeholder={t("subjectMatter") + "*"}
                    />
                </Form.Item>

                <Form.Item
                    className='contact-form-textarea'
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        },
                    ]}
                >
                    <TextArea rows={4}
                              bordered={false}
                              placeholder={t("message") + "*"}
                              style={{resize: "none"}}
                    />
                </Form.Item>

                <Form.Item>
                    <Button className="contact-form-button" htmlType="submit">
                        {t("send")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default ContactForm;

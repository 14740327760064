import {ReactComponent as Arrow} from "../../assets/images/svg/arrow.svg";
import {ReactComponent as Production} from "../../assets/images/svg/production.svg";
import React, {useContext, useEffect, useState} from "react";
import ProductCard from "./product-card/ProductCard";
import {useTranslation} from "react-i18next";
import Dropdown from "./dropdown/dropdown";
import api from "../../service/api";
import {UserContext} from "../../context/UserContext";
import FilteredProductsCard from "./product-card/FilteredProductsCard";

function Shop() {
    const [subMenu, setSubMenu] = useState();
    const {t} = useTranslation();
    const {setIsLoading} = useContext(UserContext);
    const [products, setProducts] = useState(null);
    const [categories, setCategories] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [pagination, setPagination] = useState();
    const [currentCategory, setCurrentCategory] = useState(null);
    const handleClick = (id) => {
        setSubMenu(id)
    };

    useEffect(() => {
        getData();
        getCategories();
    }, [])

    const getData = async () => {
        try {
            setIsLoading(true);
            const {data} = await api.get('/store');
            const {discountedProducts, categories} = data;
            setProducts({discountedProducts, categories});
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
    };

    const getCategories = async () => {
        try {
            setIsLoading(true);
            const {data} = await api.get('/categories');
            const {categories} = data;
            const allCategoryId = Math.random().toString(36).substring(7);
            const allCategory = {
                id: allCategoryId,
                name: t("all"),
            };
            const updatedCategories = [allCategory, ...categories];
            setCategories(updatedCategories);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = async (page) => {
        await handleFilter(currentCategory.id, currentCategory.name, page);
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const handleFilter = async (categoryId, categoryName, page = 1) => {
        setCurrentCategory({id: categoryId, name: categoryName});
        try {
            setIsLoading(true);
            if (categoryName === t("all")) {
                setFilteredProducts(null);
                await getData();
            } else if (categoryId === "discount") {
                const params = {
                    page,
                };
                const {data} = await api.get('/products', {params});
                const products = data.data;
                const filteredData = products.filter(product => product.price > product.sell_price);
                setFilteredProducts({data: filteredData, categoryName});
            } else {
                window.scrollTo({top: 0, behavior: 'smooth'});
                const response = await api.get('/products', {
                    params: {category_id: categoryId, page}
                });
                setFilteredProducts({data: response.data.data, categoryName});
                setPagination({data: response.data.meta});
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="store-container">
                <div className="store-mobile-dropdown">
                    <Dropdown categories={categories} handleFilter={handleFilter}/>
                </div>
                <div className="store-production-dropdown">
                    <span className="store-production-title"><Production/>{t("production")}</span>

                    <div className="store-list">
                        <div className="store-production-dropdown-left">
                            <div className="store-production-items">
                                <div className="store-production-title store-production-title-dropdown">
                                    <Production/>{t("production")}
                                </div>
                                <ul>
                                    {categories?.map((category) => (
                                        <li className={`store-category ${subMenu === category.id && 'active'}`}
                                            onMouseOver={() => handleClick(category.id)} key={category.id}
                                            onClick={() => handleFilter(category.id, category.name)}>
                                            <p>{category.name}</p>
                                            <Arrow/>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                {filteredProducts === null ?
                    <ProductCard products={products} handleFilter={handleFilter}/> :
                    <FilteredProductsCard
                        handlePageChange={handlePageChange}
                        pagination={pagination}
                        products={filteredProducts}
                        categoryName={filteredProducts.categoryName}
                        handleFilter={handleFilter}
                    />
                }
            </div>
        </>
    );
}

export default Shop;

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {IMAGE_URL_BASE} from "../../../utils/url.helper";
import {ReactComponent as Add} from "../../../assets/images/svg/shopping-1.svg";
import {ReactComponent as Percent} from "../../../assets/images/svg/percentw.svg";
import {ReactComponent as CardHover} from "../../../assets/images/svg/card-hover.svg";
import useCart from "../../../hooks/useCart";
import {Pagination} from "antd";

function FilteredProductsCard({pagination, handlePageChange, products, categoryName, handleFilter, categoryId}) {
    const {t, i18n} = useTranslation();
    const {addToCart} = useCart();
    const [quantity] = useState(1);

    useEffect(() => {
        if (categoryId !== null && categoryId !== undefined) {
            handleFilter(categoryId);
        }    }, [categoryId, handleFilter]);
    const handleAddToCart = (id) => {
        const productData = {
            product_id: id,
            quantity: quantity
        };
        addToCart(productData);
    };

    return (
        <>
            <div className="store-production-cards-title store-production-sale">
                <span>{categoryName}</span>
            </div>
            <div className="store-production-cards">
                {products?.data.map((product) => (
                    <div className="store-production-card" key={product.id}>
                        <Link to={(`/${i18n.language}/product/${product.id}`)}>
                            <div className="store-production-card-img">
                                <img src={`${IMAGE_URL_BASE}${product.featured_image}`}
                                     alt={product.name}/>
                                {
                                    product.price > product.sell_price &&
                                    (
                                        <div className="store-production-card-sale">
                                            <Percent/>
                                        </div>
                                    )
                                }
                                <div className="store-production-card-hover">
                                    <CardHover/>
                                </div>

                            </div>
                            <div className="store-production-card-title">
                                <p>{product.company.name}</p>
                            </div>
                            <div className="store-production-card-text">
                                <p>{product.name}</p>
                            </div>
                            <div className="store-production-card-price">
                                <p>{product.sell_price}₾</p>
                                {product.price > product.sell_price &&
                                    <p>{product.price}₾</p>
                                }
                            </div>
                        </Link>
                        <button className="store-production-card-add"
                                onClick={() => handleAddToCart(product.id)}>
                            <span><Add/></span>
                            <p>{t("addToCard")}</p>
                        </button>
                    </div>
                ))}
            </div>
            <div className="orders-pagination">
                <Pagination current={pagination?.data.current_page} total={pagination?.data.total}
                            defaultPageSize={12} onChange={handlePageChange} showSizeChanger={false}/>
            </div>
        </>
    );
}

export default FilteredProductsCard;

import {Drawer, Empty} from "antd";
import {ReactComponent as Close} from "../../../../assets/images/svg/close-cart.svg";
import useCart from "../../../../hooks/useCart";
import CartItem from "../../cart-item/CartItem";
import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


function Cart(props) {
    const {carts} = useCart();
    const {t, i18n} = useTranslation();
    return (
        <Drawer className="mobile-cart" width={"100%"} title={<Close onClick={props.close}/>} placement="right"
                onClose={props.close} open={props.open} closable={false}>
            <div className="mobile-cart-container">
                <div className="mobile-cart-title">
                    <span>{t('basket')}</span>
                    <hr/>
                </div>
                {
                    carts?.cart.length === 0 ? (
                        <Empty description={t("cartEmpty")}/>
                    ) : (
                        <>
                            <div className="menu-cart-cards">

                                {carts?.cart.map((cart) => (
                                    <div key={cart.id}>
                                        <CartItem key={cart.id} cart={cart}/>
                                        <hr/>
                                    </div>
                                ))}
                            </div>
                            <div className="mobile-cart-sum">
                                <div className="mobile-cart-product-sum">
                                    <span>{carts?.totalItem} {t('productTotal')}</span>
                                </div>
                                <div className="mobile-cart-price-sum"><span>{carts?.saleAmount.toFixed(2)}₾</span></div>
                            </div>
                            <Link to={(`/${i18n.language}/order`)} className="mobile-cart-button" onClick={props.close}>{t("pay")}</Link>
                        </>
                    )
                }

            </div>
        </Drawer>
    );
}

export default Cart;

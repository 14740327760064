import {createContext, useState} from "react";

const defaultContext = {
    userToken: null,
    isLoading: false,
    setUserToken: () => {
    },
    setIsLoading: () => {
    }
}

export const UserContext = createContext(defaultContext)

function UserProvider({children}) {
    const [userToken, setUserToken] = useState(localStorage.getItem('token'));
    const [isLoading, setIsLoading] = useState(false);
    return (
        <UserContext.Provider value={{userToken, setUserToken, isLoading, setIsLoading}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
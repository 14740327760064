import React, {useContext} from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {UserContext} from "../context/UserContext";


function PrivateRoutes() {
    const {i18n} = useTranslation()
    const {userToken} = useContext(UserContext);

    return (
        !!userToken ? <Outlet/> : <Navigate to={`/${i18n.language}/`}/>
    );
}

export default PrivateRoutes;
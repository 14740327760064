import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import api from "../../service/api";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Slider() {
    const route = useLocation()
    const [slides, setSlides] = useState([]);
    const {i18n} = useTranslation();


    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const {data: {slides}} = await api.get('/slides');
        setSlides(slides);
    };

    function addPrefixToUrl(url) {
        if (!url) {
            return null;
        }
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = `https://www.${url}`;
        }
        return url;
    }

    return (
        <div className={`slider ${route.pathname === `/${i18n.language}/shop` && 'no-leaf'}`}>
            {slides.length > 0 && <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 3000
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
            >
                {
                    slides.filter(slide => Boolean(slide.title)).map((slide, index) =>
                        <SwiperSlide key={index}>
                            <img src={`${IMAGE_URL_BASE}${slide.image}`} alt={slide.title} style={{height: "460px"}}/>
                            <a href={addPrefixToUrl(slide?.url)} target="_blank">
                                <div className="gradient"/>
                            </a>
                            <div className="slide-text">
                                <h1>{slide.title}</h1>
                                <p>{slide.short_description}</p>
                            </div>
                        </SwiperSlide>
                    )
                }
            </Swiper>}
        </div>
    );
}

export default Slider;
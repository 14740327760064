import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import api from '../../service/api';
import RenderOrderPanels from '../../components/orders/RenderOrderPanels';
import {UserContext} from "../../context/UserContext";

function Orders() {
    const [activeTab, setActiveTab] = useState(1);
    const {t} = useTranslation();
    const token = localStorage.getItem('token');
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setMeta] = useState({meta: {current_page: 1, total: 0}});
    const {setIsLoading} = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const params = {
                    page: currentPage,
                    delivered: activeTab === 2 ? '1' : '',
                };
                const response = await api.get('/orders', {headers, params});
                setOrders(response.data.data);
                setMeta(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [token, currentPage, activeTab]);


    const handlePageChange = page => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleTabClick = id => {
        setActiveTab(id);
        setCurrentPage(1);
    };

    return (

        <div className="orders">

            <div className="orders-title">
                <span>{t("orders")}</span>
            </div>
            <div className="orders-tabs">
                <span
                    className={`order-tab ${activeTab === 1 && 'active'}`}
                    onClick={() => handleTabClick(1)}
                >
                    {t('current')}
                </span>
                <span
                    className={`order-tab ${activeTab === 2 && 'active'}`}
                    onClick={() => handleTabClick(2)}
                >
                    {t('finished')}
                </span>
                <hr/>
            </div>
            {
                activeTab === 1 ? (
                    <>
                        <RenderOrderPanels
                            orders={orders}
                            description={t("emptyOrders")}
                            meta={meta}
                            handlePageChange={handlePageChange}
                        />
                    </>
                ) : (
                    <>
                        <RenderOrderPanels
                            orders={orders}
                            description={t("emptyOrders")}
                            meta={meta}
                            handlePageChange={handlePageChange}
                        />
                    </>
                )
            }
        </div>
    );
}

export default Orders;

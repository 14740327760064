import {Link, NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {ReactComponent as HeaderLogo} from "../../assets/images/svg/header-logo.svg";
import Ka from "../../assets/images/png/ka.png";
import En from "../../assets/images/png/en.png";
import Ru from "../../assets/images/png/ru.png";
import {ReactComponent as Shopping} from "../../assets/images/svg/shopping.svg";
import {ReactComponent as Account} from "../../assets/images/svg/account.svg";
import {ReactComponent as Arrow} from "../../assets/images/svg/arrow.svg";
import MainTitle from "../main-title/MainTitle";
import {ReactComponent as HeaderToggle} from "../../assets/images/svg/toggle.svg";
import {ReactComponent as Store} from "../../assets/images/svg/store.svg";
import MobileMenu from "../menu/Menu";
import {useContext, useEffect, useRef, useState} from "react";
import Logo from "../../assets/images/png/menu-logo.png";
import MobileCart from "../cart/mobile/cart/Cart";
import {useTranslation} from "react-i18next";
import Cart from "../cart/Cart";
import Slider from "../slider/Slider";
import MobileSlider from "../mobile-slider/MobileSlider";
import {ModalContext} from "../../context/ModalContext";
import {UserContext} from "../../context/UserContext";
import useCart from "../../hooks/useCart";

const langs = ['ka', 'en', 'ru'];

function Header() {

    const location = useLocation();
    const params = useParams();
    const [open, setOpen] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpenCart, setIsOpenCart] = useState(false);
    const [openCart, setOpenCart] = useState(false);
    const {t, i18n, i18n: {language}} = useTranslation();
    const navigate = useNavigate()
    const {carts, updateData} = useCart();
    const {userToken} = useContext(UserContext)
    const {openModal} = useContext(ModalContext);
    const changeLanguage = async (lng) => {
        if (lng !== language) {
            await i18n.changeLanguage(lng)
            const path = location.pathname.replace(params.lang, lng);
            navigate(path);
            window.location.reload(true);
        }
    }

    useEffect(() => {
        changeLanguage(localStorage.getItem("i18nextLng"));
    }, [])

    useEffect(() => {
        if (userToken) updateData()
    }, [userToken])

    const showDrawer = () => {
        setOpen(true);
    };
    const showCart = () => {
        if (!!userToken) {
            setIsOpenCart(true);
        } else {
            openModal();
        }
    };


    const languages = {
        ka: <span key="ka" onClick={() => changeLanguage('ka')}><img src={Ka} alt="ka"/> <p> ქართული </p></span>,
        en: <span key="en" onClick={() => changeLanguage('en')}><img src={En} alt="en"/> <p> English </p></span>,
        ru: <span key="ru" onClick={() => changeLanguage('ru')}><img src={Ru} alt="ru"/> <p> Русский </p></span>,
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenCart(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleOpenAuth = () => {
        if (!!userToken) {
            navigate(`/${i18n.language}/profile`)
        } else {
            openModal();
        }
    }
    const handleOpen = () => {
        if (!!userToken) {
            setOpenCart(!openCart);
        } else {
            openModal();
        }
    }

    return (
        <>
            <header>
                <div className="header-container">
                    <div className="header-pages-logo">
                        <HeaderLogo/>
                        <Link to={`/${i18n.language}/`}><img src={Logo} alt="logo"/></Link>
                    </div>
                    <div className="header-nav-item">
                        <div className="language-selection">
                            <div className="language">
                                {languages[language]}<Arrow className="arrow"/>
                            </div>
                            <div className="language-dropdown-content">
                                {langs.map((lang) => {
                                    if (lang !== language) {
                                        return languages[lang]
                                    }
                                    return false
                                })}
                            </div>
                        </div>
                        <div className="header-nav-list">
                            <Account className={`header-account ${userToken ? 'active' : ''}`} onClick={handleOpenAuth} />
                            <div className="header-nav-cart-container" ref={wrapperRef}>
                                <div className={`header-nav-cart ${openCart ? "color" : ""}`}
                                     onClick={handleOpen}>
                                    <Shopping/>
                                </div>
                                <Cart open={openCart} close={() => setOpenCart(false)}/>
                            </div>

                        </div>
                        <div className="header-store">
                            <Link to={`/${i18n.language}/shop`}>
                                <button><Store/>{t("shop")}</button>
                            </Link>
                        </div>
                    </div>
                    <div className="header-toggle">
                        <HeaderToggle onClick={showDrawer}/>
                    </div>
                </div>

                <div className="pages-menu menu-container font-face-hn">
                    <ul>
                        <li><NavLink to={`/${i18n.language}/`}>{t("main")}</NavLink></li>
                        <li><NavLink to={`/${i18n.language}/news`}>{t("news")}</NavLink></li>
                        <li><NavLink to={`/${i18n.language}/about-us`}>{t("aboutUs")}</NavLink></li>
                        <li><NavLink to={`/${i18n.language}/municipality`}>{t("municipality")}</NavLink></li>
                        <li><NavLink to={`/${i18n.language}/terms-conditions`}>{t("termsConditions")}</NavLink></li>
                        <li><NavLink to={`/${i18n.language}/contact`}>{t("contact")}</NavLink></li>
                    </ul>
                </div>
            </header>
            {location.pathname === `/${i18n.language}` || location.pathname === `/${i18n.language}/` ? (
                <div key="logo-container">
                    <MainTitle/>
                    <Slider className="header-slider-desktop"/>
                    <MobileSlider className="header-slider-mobile"/>
                </div>
            ) : (
                <div className="pages-mobile-menu">
                    <div className="pages-menu-logo">
                        <Link to={`/${i18n.language}/`}><img src={Logo} alt="menu-logo"/></Link>
                    </div>
                    <div
                        className={location.pathname === `/${i18n.language}/online-shop` || `/${i18n.language}/shop` ? 'pages-menu-text-logo-hide' : 'pages-menu-text-logo'}>
                    </div>
                    <div className="pages-mobile-menu-shopping" onClick={showCart}>
                        <span>{carts?.totalItem}</span>
                        <Shopping/>
                    </div>
                </div>
            )}
            <MobileMenu open={open} close={() => setOpen(false)}/>
            <MobileCart open={isOpenCart} close={() => setIsOpenCart(false)}/>
        </>
    );
}

export default Header;

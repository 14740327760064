import {ReactComponent as Call} from "../../assets/images/svg/call.svg";
import {ReactComponent as Mail} from "../../assets/images/svg/mail.svg";
import {ReactComponent as Facebook} from "../../assets/images/svg/facebook.svg";
import {ReactComponent as Instagram} from "../../assets/images/svg/instagram.svg";
import {ReactComponent as Youtube} from "../../assets/images/svg/youtube.svg";
import {ReactComponent as Linkedin} from "../../assets/images/svg/linkedin.svg";
import {ReactComponent as Tiktok} from "../../assets/images/svg/tiktok.svg";
import {ReactComponent as RightLeaf} from "../../assets/images/svg/top-right-leaf.svg";
import {ReactComponent as LeftLeaf} from "../../assets/images/svg/bottom-right-leaf.svg";
import React, {useContext, useEffect, useState} from "react";
import SuccessPopup from "../../components/success-popup/SuccessPopup";
import {useTranslation} from "react-i18next";
import api from "../../service/api";
import {parseUriFromHTMLTag} from "../../utils/string.helper";
import ContactForm from "./contact-form/ContactForm";
import {UserContext} from "../../context/UserContext";
import {Form, message} from "antd";

const getIconForPlatform = (platform) => {
    switch (platform) {
        case 'facebook':
            return <Facebook/>;
        case 'instagram':
            return <Instagram/>;
        case 'linkedin':
            return <Linkedin/>;
        case 'tiktok':
            return <Tiktok/>;
        case 'youtube':
            return <Youtube/>;
        default:
            return null;
    }
};

function Contact() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contacts, setContacts] = useState(null);
    const {t} = useTranslation();
    const {setIsLoading} = useContext(UserContext);
    const [form] = Form.useForm();
    const [errors, setErrors] = useState('');

    const showModal = () => {
        setIsModalOpen(true);
    };


    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setIsLoading(true);
            const {data} = await api.get('/contact');
            const {'contact-info': contactInfo, 'contact-persons': contactPerson} = data;
            setContacts({contactInfo, contactPerson});
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
    };

    const onFinish = async (values) => {
        try {
            setIsLoading(true);
            const res = await api.post('/contact', values);
            if (res.data.status === 'success') {
                showModal();
                form.resetFields();
            }
        } catch (e) {
            setErrors(e.response?.data?.errors)
            if (e.response.status === 500) {
                message.error(t("anErrorOccurred"));
            }
        } finally {
            setIsLoading(false);
        }
    }


    if (!contacts || !contacts.contactInfo) return null;
    const {contactInfo} = contacts;

    return (
        <>
            <div className="contact pages-container">

                <div className="contact-top">
                    <div className="contact-left-side">
                        <div className="contact-left-side-title">
                            <h1>ბოლნისის</h1>
                            <h1>აგროცენტრი</h1>
                        </div>
                        <div className="contact-info">
                            <ul>
                                <li><strong>{t("contactInfo")}</strong></li>
                                <li>{contacts?.contactInfo.address}</li>
                                <li>
                                    <a href={`tel:${contacts?.contactInfo.phone}`}>
                                        <Call/>{contacts?.contactInfo.phone}
                                    </a>
                                </li>
                                <li className="contact-mail">
                                    <a href={`mailto:${contacts?.contactInfo.email}`}>
                                        <Mail/>{contacts?.contactInfo.email}
                                    </a>
                                </li>
                                <ul className="contact-social-media">
                                    {Object.entries(contactInfo).map(([platform, link]) => (
                                        link && (
                                            <li key={platform}>
                                                <a href={link} target="_blank" rel="noreferrer">
                                                    {getIconForPlatform(platform)}
                                                </a>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </ul>
                        </div>
                    </div>

                    <div className="contact-right-side">
                        <div className="contact-right-leaf">
                            <RightLeaf/>
                        </div>
                        <ContactForm onFinish={onFinish} form={form} errors={errors}/>
                    </div>
                </div>

                <div className="contact-cards-title">
                    <h4>{t("contactPersons")}</h4>
                    <div className="contact-bottom-left-leaf">
                        <LeftLeaf/>
                    </div>
                </div>

                <div className="contact-cards">

                    {contacts?.contactPerson.map((contact, index) => (
                        <div
                            key={index}
                            className="contact-card"
                        >
                            <div className="contact-card-back"/>
                            <div className="contact-card-top">
                                <span>{contact.department}</span>
                            </div>
                            <div className="contact-card-bottom">
                                <ul>
                                    <li><strong>{contact.name} {contact.surname}</strong></li>
                                    <li>
                                        <a href={`tel:${contact.phone}`}>
                                            <Call/><span>{contact.phone}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${contact.email}`}>
                                            <Mail/><span>{contact.email}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="contact-top">
                    <div className="contact-right-side for-mobile">
                        <div className="contact-right-leaf">
                            <RightLeaf/>
                        </div>
                        <ContactForm onFinish={onFinish} form={form} errors={errors}/>
                    </div>
                </div>
            </div>

            {contacts?.contactInfo.map &&
                <iframe
                    title="bolnisi-map"
                    className="google-map"
                    src={parseUriFromHTMLTag(contacts?.contactInfo?.map)}
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            }

            <SuccessPopup open={isModalOpen} close={() => setIsModalOpen(false)}/>
        </>
    );
}

export default Contact;

import React from 'react';

function ProductList({products}) {
    return (
        <div className="order-detail-container">
            {products.map((product) => (
                <div className="order-detail" key={product.product.id}>
                    <span>{product.product.name}</span>
                    <hr/>
                    <span>x{product.quantity}</span>
                    <span>{product.product.sell_price}₾</span>
                </div>
            ))}
        </div>
    );
}

export default ProductList;
import {Link} from "react-router-dom";
import {ReactComponent as Add} from "../../../assets/images/svg/shopping-1.svg";
import {ReactComponent as Percent} from "../../../assets/images/svg/percentw.svg";
import {ReactComponent as CardHover} from "../../../assets/images/svg/card-hover.svg";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {IMAGE_URL_BASE} from "../../../utils/url.helper";
import {ReactComponent as Arrow} from "../../../assets/images/svg/arrow.svg";
import useCart from "../../../hooks/useCart";
import {Empty} from "antd";

function ProductCard({products, handleFilter}) {
    const {t, i18n} = useTranslation();
    const {addToCart} = useCart();
    const [quantity] = useState(1);

    const handleAddToCart = (id) => {
        const productData = {
            product_id: id,
            quantity: quantity
        };
        addToCart(productData);
    };
    return (
        <>
            {
                products?.discountedProducts.length > 0 ?
                    <div className="store-production-cards-title store-production-sale"
                         onClick={() => handleFilter("discount", t("discountedProducts"))}>
                        <span>{t("discountedProducts")}</span>
                        <span>{t("fully")}</span>
                        <span><Arrow/></span>
                    </div> : null
            }
            {
                products?.discountedProducts.length === 0 && products?.categories.length === 0 ?
                    <Empty className="product-empty" description={t("NoData")}/> :
                    <>
                        <div className="store-production-cards">
                            {products?.discountedProducts.filter(product => Boolean(product.name)).map((product) => (
                                <div className="store-production-card" key={product.id}>
                                    <Link to={(`/${i18n.language}/product/${product.id}`)}>

                                        <div className="store-production-card-img">
                                            <img src={`${IMAGE_URL_BASE}${product.featured_image}`} alt={product.name}/>
                                            <div className="store-production-card-sale">
                                                <Percent/>
                                            </div>
                                            <div className="store-production-card-hover">
                                                <CardHover/>
                                            </div>
                                        </div>
                                        <div className="store-production-card-title">
                                            <p>{product.company.name}</p>
                                        </div>
                                        <div className="store-production-card-text">
                                            <p>{product.name}</p>
                                        </div>
                                        <div className="store-production-card-price">
                                            <p>{product.sell_price}₾</p>
                                            <p>{product.price}₾</p>
                                        </div>
                                    </Link>
                                    <button className="store-production-card-add"
                                            onClick={() => handleAddToCart(product.id)}>
                                        <span><Add/></span>
                                        <p>{t("addToCard")}</p>
                                    </button>
                                </div>
                            ))
                            }
                        </div>
                        {products?.categories.map((category) => (
                            <div className="products-category" key={category.id}>

                                <div className="store-production-cards-title"
                                     onClick={() => handleFilter(category.id, category.name)}>
                                    <span>{category.name}</span>
                                    <span>{t("fully")}</span>
                                    <span><Arrow/></span>
                                </div>

                                <div className="store-production-cards">
                                    {[...category.products
                                        .filter(({
                                                     price,
                                                     sell_price
                                                 }) => price === sell_price), ...products.discountedProducts
                                        .filter((product) => product.category.id === category.id)].filter(product => Boolean(product.name))
                                        .map((product) => (

                                            <div className="store-production-card" key={product.id}>
                                                <Link to={(`/${i18n.language}/product/${product.id}`)}>
                                                    <div className="store-production-card-img">
                                                        <img src={`${IMAGE_URL_BASE}${product.featured_image}`}
                                                             alt={product.name}/>
                                                        {
                                                            product.price > product.sell_price &&
                                                            (
                                                                <div className="store-production-card-sale">
                                                                    <Percent/>
                                                                </div>
                                                            )
                                                        }

                                                        <div className="store-production-card-hover">
                                                            <CardHover/>
                                                        </div>

                                                    </div>
                                                    <div className="store-production-card-title">
                                                        <p>{product.company.name}</p>
                                                    </div>
                                                    <div className="store-production-card-text">
                                                        <p>{product.name}</p>
                                                    </div>
                                                    <div className="store-production-card-price">
                                                        <p>{product.sell_price}₾</p>
                                                        {product.price > product.sell_price &&
                                                            <p>{product.price}₾</p>
                                                        }
                                                    </div>
                                                </Link>
                                                <button className="store-production-card-add"
                                                        onClick={() => handleAddToCart(product.id)}>
                                                    <span><Add/></span>
                                                    <p>{t("addToCard")}</p>
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </>
            }
        </>
    );
}

export default ProductCard;

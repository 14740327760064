import React from "react";
import usePages from "../../hooks/usePages";

function TermsConditions() {
    const {pageData} = usePages(3);
    return (
        <div className="terms-conditions-container">
            <h1>
                {pageData?.page.title}
            </h1>
            <div className="terms-conditions-text"  dangerouslySetInnerHTML={{__html: pageData?.page.content}}/>
        </div>
    )
}

export default TermsConditions;

import {Modal} from "antd";
import {ReactComponent as Success} from "../../assets/images/svg/success-popup.svg";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {SuccessContext} from "../../context/SuccessContext";

function SuccessPopup(props) {
    const {t} = useTranslation();
    const {setIsSuccess} = useContext(SuccessContext);
    const handleClick = () => {
        props.close();
        setIsSuccess(false);
    }
    return (


        <Modal className="success-popup" open={props.open} onCancel={handleClick} footer={false} closable={false}>

            <div className="success-popup">
                <div className="success-popup-content-connect">
                    <div className="success-popup-svg"></div>
                    <div className="success-popup-text">
                        <p><Success/></p>
                        <p>{t("messageSent")}</p>
                    </div>
                    <div className="success-popup-button" onClick={handleClick}><span>{t("close")}</span></div>
                </div>
            </div>
        </Modal>
    );
}

export default SuccessPopup;
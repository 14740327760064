import React from 'react';
import {ReactComponent as FacebookIcon} from "../../assets/images/svg/facebook.svg";
import {useTranslation} from "react-i18next";

const FacebookShareButton = ({url}) => {
    const {t} = useTranslation();
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

    return (
        <a
            href={shareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="social_media_btn"
        >
            <FacebookIcon/>
            <p>{t('share')}</p>
        </a>
    );
};

export default FacebookShareButton;

import {ReactComponent as Arrow} from "../../assets/images/svg/arrow.svg";
import {Link} from "react-router-dom";
import HomeOffers from "../home-offers/HomeOffers";
import {useTranslation} from "react-i18next";
import NewsCard from "./news-card/NewsCard";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import api from "../../service/api";

function HomeNews() {
    const {t, i18n} = useTranslation();
    const [posts, setPosts] = useState(null);
    const {setIsLoading} = useContext(UserContext);


    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await api.get("/main");
            const featuredPosts = response.data['featuredPosts'];
            const offers = response.data['offers'];
            setPosts({featuredPosts, offers});
        } catch {

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className="home-news-container">

                <h3>{t("news")}</h3>

                <div className="home-news-cards">
                    <NewsCard posts={posts}/>
                </div>

                <div className="home-all-news">
                    <Link to={`/${i18n.language}/news`}>
                        <div className="all-news">
                            <span><Arrow/></span>
                            <h4>{t("allNews")}</h4>
                        </div>
                    </Link>
                </div>
                <hr/>
            </div>
            <HomeOffers/>
        </>
    );
}

export default HomeNews;
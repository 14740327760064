import React from 'react';
import {useTranslation} from "react-i18next";

function OrdersList({order}) {
    const {t} = useTranslation();

    function getStatusMessage(status) {
        switch (status) {
            case "Pending":
                return t("pending");
            case "Shipped":
                return t("shipped");
            case "Delivered":
                return t("delivered");
            case "Rejected":
                return t("rejected");
            case "Accepted":
                return t("accepted");
            default:
                return null;
        }
    }

    const statusMessage = getStatusMessage(order.status);
    const totalQuantity = order.products.reduce((total, product) => total + product.quantity, 0);

    return (
        <div className="order-current-tabs">
            <div className="order-tab-title">
                <p>{t("order")} #{order.id} | </p>
                <p>{totalQuantity} {t("productTotal")}
                    <span className="order-current-tab-price"> {order.total_price.toFixed(2)}₾</span>
                </p>
            </div>
            <span className="current-tab-progress-desktop">
                {statusMessage && <p>{statusMessage}</p>}
            </span>
        </div>
    );
}

export default OrdersList;

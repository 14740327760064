import React from "react";
import {Helmet} from "react-helmet";
import {ReactComponent as NewsIcon} from "../../assets/images/svg/news.svg";
import {ReactComponent as EventIcon} from "../../assets/images/svg/calendar.svg";
import {ReactComponent as AnnouncementIcon} from "../../assets/images/svg/announcement.svg";
import {ReactComponent as PercentIcon} from "../../assets/images/svg/percent.svg";
import {useData} from "./hooks/useData";
import SimilarNews from "../../components/similar-news/SimilarNews";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {useTranslation} from "react-i18next";
import FacebookShareButton from "../../components/facebook-share/FacebookShareButton";
import {Image} from "antd";

const icons = {
    1: <AnnouncementIcon/>,
    2: <EventIcon/>,
    3: <NewsIcon/>,
    4: <PercentIcon/>,
};

function NewsDetail() {
    const {post} = useData();
    const {t, i18n} = useTranslation();
    const postUrl = `https://bolnisiagrocenter.ge/${i18n.language}/news/${post?.id}`;
    const imageUrl = `${IMAGE_URL_BASE}${post?.featured_image}`;

    return (
        <div className="news-detail-container">
            <Helmet>
                <meta property="og:url" content={postUrl}/>
                <meta property="og:title" content={post?.title}/>
                <meta property="og:description" content={post?.short_description}/>
                <meta property="og:image" content={imageUrl}/>
            </Helmet>

            <div className="news-detail">
                <div className="news-detail-date-category">
                    <span>{post?.publish_date}</span>
                    <span>
            {icons[post?.category.id]}
                        {post?.category.name}
          </span>
                </div>
                <div className="news-detail-image">
                    <img src={imageUrl} alt={post?.title}/>
                </div>
                <div className="news-detail-title">
                    <span>{post?.short_description}</span>
                </div>
                <div
                    className="news-detail-text"
                    dangerouslySetInnerHTML={{__html: post?.content}}
                />
                <FacebookShareButton platform="facebook" url={postUrl}/>
            </div>

            <div className="news-detail-album">
                <Image.PreviewGroup>
                    {post?.gallery.map((image) => (
                        <div key={image.id} className="news-detail-photo">
                            <Image src={`${IMAGE_URL_BASE}${image.url}`} alt="gallery"/>
                        </div>
                    ))}
                </Image.PreviewGroup>
            </div>

            <div className="news-detail-similar-news-title">
                <h2>{t("similarNews")}</h2>
            </div>
            <SimilarNews/>
        </div>
    );
}

export default NewsDetail;

import HomeNews from "../components/home-news/HomeNews";
import Contact from "./contact/Contact";
import News from "./news/News";
import AboutUs from "./about-us/AboutUs";
import TermsConditions from "./terms-conditions/TermsConditions";
import Municipality from "./municipality/Municipality";
import Profile from "./profile/Profile";
import Product from "./product/Product";
import NewsDetail from "./news-detail/NewsDetail";
import OnlineStore from "./online-shop/OnlineShop";
import Shop from "./shop/Shop";
import Order from "./order/Order";

function getRoutes() {
    return [
        {
            isDefault: true,
            path: "",
            component: <HomeNews/>
        },
        {
            isDefault: false,
            path: 'contact',
            component: <Contact/>
        },
        {
            isDefault: false,
            path: 'news',
            component: <News/>
        },
        {
            isDefault: false,
            path: 'about-us',
            component: <AboutUs/>
        },
        {
            isDefault: false,
            path: 'municipality',
            component: <Municipality/>
        },
        {
            isDefault: false,
            path: 'terms-conditions',
            component: <TermsConditions/>
        },
        {
            isDefault: false,
            path: 'profile',
            component: <Profile/>
        },
        {
            isDefault: false,
            path: 'shop',
            component: <Shop/>
        },
        {
            isDefault: false,
            path: 'online-shop/:id',
            component: <OnlineStore/>
        },
        {
            isDefault: false,
            path: 'news/:id',
            component: <NewsDetail/>
        },
        {
            isDefault: false,
            path: 'product/:id',
            component: <Product/>
        },
        {
            isDefault: false,
            path: 'order',
            component: <Order/>
        },
    ]

}

export default getRoutes;

import React from 'react';
import {ReactComponent as Basket} from "../../assets/images/svg/shopping-1.svg";
import {useTranslation} from "react-i18next";
import {ReactComponent as Percent} from "../../assets/images/svg/percentw.svg";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {Link} from "react-router-dom";
import useCart from "../../hooks/useCart";
import {Empty} from "antd";

function Card({data}) {
    const {t, i18n} = useTranslation();
    const {addToCart} = useCart();
    const addCart = (product_id) => {
        addToCart({quantity: 1, product_id})
    };

    return (
        <div className="cards">
            {
                data?.length === 0 ? <Empty description={t("NoData")}/> :
                    <>
                        {data?.filter(data => Boolean(data.name)).map((data) => (
                            <div className="card" key={data.id}>
                                <Link to={(`/${i18n.language}/product/${data.id}`)}>
                                    <div className="card-image">
                                        <img src={`${IMAGE_URL_BASE}${data.featured_image}`} alt={data.name}/>
                                        {
                                            data.price > data.sell_price &&
                                            <div className="product-sale"><Percent/></div>
                                        }
                                    </div>
                                </Link>
                                <div className="card-price">
                                    <p>{data.sell_price}₾</p>
                                    {
                                        data.price > data.sell_price &&
                                        <p>{data.price}₾</p>
                                    }
                                </div>
                                <p>{data.name}</p>
                                <button onClick={() => addCart(data.id)}><Basket/>{t('addToCard')}</button>
                            </div>
                        ))}
                    </>
            }

        </div>
    );
}

export default Card;
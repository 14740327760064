import CartItem from "./cart-item/CartItem";
import useCart from "../../hooks/useCart";
import {Empty} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


function Cart(props) {
    const {carts} = useCart();
    const {i18n, t} = useTranslation();

    return (
        <>
            <p className="header-cart-quantity">{carts?.totalItem}</p>
            <div className={`header-cart-container ${props.open ? "show" : "hide"}`} onClick={() => props.open}>
                <div className="header-cart">
                    {
                        carts?.totalItem > 0 ? (
                            <div className="header-cart-items">
                                {carts?.cart.map((cart) => (
                                    <CartItem key={cart.id} cart={cart}/>
                                ))}
                                <hr/>
                                <div className="header-cart-bottom">
                                    <Link to={(`/${i18n.language}/order`)}>
                                        <button className="header-cart-button" onClick={props.close}>{t("pay")}</button>
                                    </Link>
                                    <div className="header-cart-sum">
                                        <p>{carts?.totalItem} {t("productTotal")}</p>
                                        <p>{carts?.saleAmount.toFixed(2)}₾</p>
                                    </div>
                                </div>
                            </div>
                        ) : (<Empty description={t("cartEmpty")}/>)
                    }
                </div>
            </div>
        </>
    );
}

export default Cart;

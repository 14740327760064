import {createContext, useState} from "react";

const defaultContext = {
    isOpen: false,
    setIsOpen: () => {
    }
}

export const ModalContext = createContext(defaultContext);

export const ModalProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <ModalContext.Provider value={{isOpen, openModal, closeModal}}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
import React, {useEffect} from 'react';
import {ReactComponent as Refresh} from "../../../assets/images/svg/refresh.svg";
import {useTranslation} from "react-i18next";

function Timer({disable, setDisable, resendOTP, isLegal, remainingTime, setRemainingTime}) {
    const {t} = useTranslation();


    useEffect(() => {
        const countdown = setInterval(() => {
            if (remainingTime > 0) {
                setRemainingTime(remainingTime - 1);
            }
            if (remainingTime === 1) {
                setDisable(false);
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [remainingTime]);

    const handleClickResend = () => {
        setRemainingTime(30);
        setDisable(true);
        resendOTP(isLegal);
    };

    const minutes = Math.floor(remainingTime / 60).toString().padStart(2, "0");
    const seconds = (remainingTime % 60).toString().padStart(2, "0");


    return (
        <div className="registration-phone-number-footer">
            <div className="registration-input-code-time">
                <p>{`${minutes}:${seconds}`}</p>
            </div>
            <div className="registration-repeat-input-code" style={{
                cursor: disable ? 'not-Allowed' : 'pointer',
                opacity: disable ? '0.5' : '1'
            }}
            >
                <span
                    style={{pointerEvents: disable ? 'none' : 'unset'}}
                    onClick={handleClickResend}
                >
                    <span className="resend-sms">
                        <Refresh/>
                    </span>
                    {t("sendCode")}
                </span>
            </div>
        </div>
    );
}

export default Timer;
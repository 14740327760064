import React from 'react';
import {ReactComponent as Shopping} from "../../../assets/images/svg/shopping-1.svg";
import {ReactComponent as CardHover} from "../../../assets/images/svg/card-hover.svg";
import {IMAGE_URL_BASE} from "../../../utils/url.helper";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useCart from "../../../hooks/useCart";

function OffersCard({offers}) {
    const {i18n} = useTranslation();
    const {addToCart} = useCart();


    const addCart = (product_id) => {
        addToCart({quantity: 1, product_id})
    };
    return (
        <div className="home-offers-cards">
            {offers?.offers.map((offer) => (
                <div
                    key={offer.id}
                    className="home-offers-card"
                >

                    <div className="home-offers-card-img">
                        <Link to={(`/${i18n.language}/product/${offer.id}`)}>

                            <img src={`${IMAGE_URL_BASE}${offer.featured_image}`} alt={offer.name}/>
                            <div className="home-offers-img-hover">
                                <CardHover/>
                            </div>
                        </Link>
                        <div className="home-offers-card-shopping" onClick={() => addCart(offer.id)}>
                            <Shopping/>
                        </div>
                    </div>

                    <div className="home-offers-card-title">
                        <p>{offer.name}</p>
                        {/*<h3>{offer}</h3>*/}
                    </div>
                    <div className="home-offers-card-price">
                        <h1>{offer.sell_price}₾</h1>
                        <h2>{offer.price}₾</h2>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default OffersCard;
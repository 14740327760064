import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PaymentSuccess = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className="payment">
            <div className="payment-block">
            <h2>{t("paymentSuccess")}</h2>
            <Link to={`/${i18n.language}/profile`}>
                <button>{t("viewOrder")}</button>
            </Link>
            </div>
        </div>
    );
};

export default PaymentSuccess;

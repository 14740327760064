import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import api from '../../service/api';

const ResetPassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { setIsLoading } = useContext(UserContext);
    const { token, email } = useParams();
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            email: email,
            token: token,
        });
    }, [form, email, token]);

    const handleResetPassword = async (values) => {
        try {
            setIsLoading(true);
            const { data } = await api.post('/reset-password', values, {
                headers: {
                    'Password-reset': true,
                },
            });
            if (data.status === "success") {
                setIsError(false);
                message.success(t("passwordChanged"));
                navigate(`/${i18n.language}/`);
            }
        } catch (e) {
            setIsError(true);
            setErrors(e.response.data.errors.password);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="profile-form reset-password">
            <Form className="reset-password-form" layout="vertical" form={form} onFinish={handleResetPassword}>
                <Form.Item
                    className="registration-input"
                    name="email"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="token"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('newPassword')}
                    validateStatus={isError ? 'error' : ''}
                    help={isError ? errors.map((error, index) => <div key={index}>{error}</div>) : ''}
                >
                    <Input.Password visibilityToggle={false} />
                </Form.Item>
                <Form.Item
                    name="password_confirmation"
                    label={t('repeatPassword')}
                    validateStatus={isError ? 'error' : ''}
                    help={isError ? errors.map((error, index) => <div key={index}>{error}</div>) : ''}
                >
                    <Input.Password visibilityToggle={false} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit">{t('changePassword')}</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ResetPassword;

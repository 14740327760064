import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
api.interceptors.request.use(function (config) {

    config.headers = config.headers || {};
    config.params = {...config.params, lang: localStorage.getItem('i18nextLng')};
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default api;
import {Drawer} from "antd";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {ReactComponent as Arrow} from "../../assets/images/svg/arrow.svg";
import {ReactComponent as LoginIcon} from "../../assets/images/svg/account.svg";
import {ReactComponent as Shopping} from "../../assets/images/svg/shopping.svg";
import {ReactComponent as Store} from "../../assets/images/svg/store.svg";
import Ka from "../../assets/images/png/ka.png";
import En from "../../assets/images/png/en.png";
import Ru from "../../assets/images/png/ru.png";
import Auth from "../auth/Auth";
import {useContext, useEffect, useState} from "react";
import Cart from "../cart/mobile/cart/Cart";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../context/UserContext";
import {ModalContext} from "../../context/ModalContext";

const langs = ['ka', 'en', 'ru'];

function Menu(props) {


    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpenCart, setIsOpenCart] = useState(false);
    const {t, i18n, i18n: {language}} = useTranslation();
    const {userToken} = useContext(UserContext);
    const {openModal} = useContext(ModalContext);

    const changeLanguage = async (lng) => {
        if (lng !== language) {
            await i18n.changeLanguage(lng)
            const path = location.pathname.replace(params.lang, lng);
            navigate(path);
            window.location.reload(true);
        }
    }
    useEffect(() => {
        changeLanguage(localStorage.getItem("i18nextLng"));
    }, [])

    const handleOpenAuth = () => {
        if (!!userToken) {
            navigate(`/${i18n.language}/profile`)
        } else {
            openModal();
        }
        props.close();
    };

    const showCart = () => {
        if (!!userToken) {
            setIsOpenCart(true);
        } else {
            openModal();
        }
        props.close();
    };


    const languages = {
        ka: <span key="ka" onClick={() => changeLanguage('ka')}><img src={Ka} alt="ka"/> <p> ქართული </p></span>,
        en: <span key="en" onClick={() => changeLanguage('en')}><img src={En} alt="en"/> <p> English </p></span>,
        ru: <span key="ru" onClick={() => changeLanguage('ru')}><img src={Ru} alt="ru"/> <p> Русский </p></span>,
    }
    return (
        <>
            <Drawer className='mobile-menu' width={"100%"} placement="right" open={props.open}
                    onClose={props.close}>

                <div className="mobile-menu-list">

                    <div className="language-selection-mobile-menu">
                        <div className="mobile-menu-language">
                            {languages[language]}<Arrow className="arrow"/>
                        </div>
                        <div className="mobile-menu-language-dropdown">
                            {langs.map((lang) => {
                                if (lang !== language) {
                                    return languages[lang]
                                }
                                return false
                            })}
                        </div>
                    </div>

                    <div className="mobile-menu-list-items">
                        <ul>
                            {[
                                {path: "/", label: "main"},
                                {path: "/news", label: "news"},
                                {path: "/about-us", label: "aboutUs"},
                                {path: "/municipality", label: "municipality"},
                                {path: "/terms-conditions", label: "termsConditions"},
                                {path: "/contact", label: "contact"},
                            ].map((item, index) => (
                                <Link key={index} to={`/${i18n.language}${item.path}`}>
                                    <li onClick={props.close}>
                                        <p>{t(item.label)}</p>
                                        <Arrow/>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>

                    <div className="mobile-menu-bottom">
                        <div className="mobile-menu-registration" onClick={handleOpenAuth}>
                            <LoginIcon/>
                            <p>{!!userToken ? t("profile") : t("loginRegistration")}</p>
                        </div>
                        <div className="mobile-menu-shopping" onClick={showCart}>
                            <Shopping/><p>{t("basket")}</p>
                        </div>
                        <div className="mobile-menu-button">
                            <Link to={`/${i18n.language}/shop`}>
                                <button onClick={props.close}><Store/>{t("shop")}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Auth open={isModalOpen} close={() => setIsModalOpen(false)}/>
            <Cart open={isOpenCart} close={() => setIsOpenCart(false)}/>

        </>
    );
}

export default Menu;

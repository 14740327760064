import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Layout from "./components/layout/Layout";
import getRoutes from "./pages/Routes";
import NotFound from "./pages/not-found/NotFound";
import Redirect from "./pages/redirect/Redirect";
import Profile from "./pages/profile/Profile";
import PrivateRoutes from "./utils/PrivateRoutes";
import React, {useContext, useEffect} from "react";
import {UserContext} from "./context/UserContext";
import ScrollToTop from "./utils/scrollToTop";
import Payment from "./pages/payment/Payment";
import ResetPassword from "./pages/reset-password/ResetPassword";


function App() {

    const {setUserToken} = useContext(UserContext);
    const routes = getRoutes();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setUserToken(token);
        }
    }, []);

    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Redirect/>}/>
                <Route element={<PrivateRoutes/>}>
                    <Route path="/payment-failure/:id" element={<Payment/>}/>
                    <Route path="/payment-success/:id" element={<Payment/>}/>
                </Route>
                <Route
                    path="/"
                    element={<Layout/>}
                >
                    <Route path="/reset-password/:token/:email" element={<ResetPassword/>}/>
                </Route>
                <Route
                    key="main"
                    path="/:lang"
                    element={<Layout/>}
                >
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/:lang/profile" element={<Profile/>}/>
                    </Route>
                    {routes.map(({isDefault, path, component}) => (<Route
                            key={path}
                            index={isDefault}
                            path={path}
                            element={component}
                        />
                    ))}
                </Route>
                <Route path="/404" element={<NotFound/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    );
}

export default App;

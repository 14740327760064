import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as Facebook} from "../../assets/images/svg/facebook.svg";
import {ReactComponent as Instagram} from "../../assets/images/svg/instagram.svg";
import {ReactComponent as Linkedin} from "../../assets/images/svg/linkedin.svg";
import {ReactComponent as Tiktok} from "../../assets/images/svg/tiktok.svg";
import {ReactComponent as Youtube} from "../../assets/images/svg/youtube.svg";
import {ReactComponent as Call} from "../../assets/images/svg/call.svg";
import {ReactComponent as Mail} from "../../assets/images/svg/mail.svg";
import {useTranslation} from "react-i18next";
import api from "../../service/api";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {useParams} from "react-router-dom";
import Card from "../../components/card/Card";
import {UserContext} from "../../context/UserContext";
import {Image} from "antd";
import {EyeOutlined} from "@ant-design/icons";

const getIconForPlatform = (platform) => {
    switch (platform) {
        case 'facebook':
            return <Facebook/>;
        case 'instagram':
            return <Instagram/>;
        case 'linkedin':
            return <Linkedin/>;
        case 'tiktok':
            return <Tiktok/>;
        case 'youtube':
            return <Youtube/>;
        default:
            return null;
    }
};
const previewOptions = {
    mask: (
        <div className="custom-preview-mask">
            <div className="custom-mask-info">
                <EyeOutlined/>გადახედვა
            </div>
        </div>
    ),
};

function OnlineShop() {
    const [activeTab, setActiveTab] = useState(1);
    const {t} = useTranslation();
    const {id} = useParams();
    const {setIsLoading} = useContext(UserContext);
    const [products, setProducts] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);

    const handleClick = (id) => {
        setActiveTab(id)
    };


    useEffect(() => {
        getData(id);
        getProducts(id);
    }, [id]);

    const getData = async (id) => {
        const {data} = await api.get(`/companies/${id}`);
        setCompanyInfo(data.company);
    };
    const getProducts = async (id) => {
        try {
            setIsLoading(true);
            const {data} = await api.get('/products', {
                params: {
                    company_id: id
                }
            });
            setProducts(data);
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="online-store-container">
            <div className="online-store-image">
                <img src={`${IMAGE_URL_BASE}${companyInfo?.featured_image}`} alt={companyInfo?.name}/>
            </div>

            <div className="online-store-mobile-container">
                <div className="online-store-cellar">
                    <div className="online-store-small-img">
                        <img src={`${IMAGE_URL_BASE}${companyInfo?.logo}`} alt={companyInfo?.name}/>
                    </div>
                    <div className="online-store-top-title">
                        <span>{companyInfo?.name}</span>
                    </div>

                </div>

                <div className="online-store-tabs">
                    <span className={`online-store-tab ${activeTab === 1 && 'active'}`}
                          onClick={() => handleClick(1)}>{t("production")}
                    </span>
                    <span className={`online-store-tab ${activeTab === 2 && 'active'}`}
                          onClick={() => handleClick(2)}>{t("informationInDetails")}
                    </span>
                    <hr/>
                </div>

                {activeTab === 1 ? (
                    <div className="online-detail-production">
                        <div className="online-detail-production-filter">
                            {/*<FilterTab filterItems={filterItems} handleClick={setValue}/>*/}
                        </div>

                        {/*<div className="online-detail-production-filter-mobile">*/}
                        {/*    <Dropdown*/}
                        {/*        defaultValue={t("allProducts")}*/}
                        {/*        options={options}*/}
                        {/*        onItemClick={setValue}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <Card data={products?.data}/>

                    </div>
                ) : activeTab === 2 ? (
                    <div className="online-store-detail-info">
                        <div className="online-store-description"
                             dangerouslySetInnerHTML={{__html: companyInfo?.description}}/>

                        <div className="online-store-contact">

                            <div className="online-store-contact-us">
                                <a href={`tel:${companyInfo?.phone}`}>
                                    <span><Call/>{companyInfo?.phone}</span>
                                </a>
                                <a href={`mailto:${companyInfo?.email}`}>
                                    <span className="contact-mail"><Mail/>{companyInfo?.email}</span>
                                </a>
                            </div>

                            <div>
                                <p>{companyInfo?.address}</p>
                            </div>

                            <div className="online-store-social-media">
                                {Object.entries(companyInfo || {}).map(([platform, link]) => (
                                    link && (
                                        <a key={platform} href={link} target="_blank" rel="noopener noreferrer">
                                            {getIconForPlatform(platform)}
                                        </a>
                                    )
                                ))}
                            </div>
                        </div>
                        <div className="online-store-detail-info-images">
                            <Image.PreviewGroup>
                                {companyInfo.gallery.map((image) => (
                                    <Image key={image.id} src={`${IMAGE_URL_BASE}${image?.url}`}
                                           alt={companyInfo.name}
                                           preview={previewOptions}
                                    />
                                ))}
                            </Image.PreviewGroup>

                        </div>
                    </div>
                ) : null}

            </div>
        </div>
    );
}

export default OnlineShop;
import {createContext, useState} from "react";

const defaultContext = {
    carts: null,
}

export const CartContext = createContext(defaultContext);

export const CartProvider = ({children}) => {
    const [carts, setCarts] = useState(null);

    return (
        <CartContext.Provider value={{carts, setCarts}}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, Select} from "antd";
import InputCode from "react-code-input";
import {ReactComponent as Sms} from "../../../assets/images/svg/sms.svg";
import Timer from "../timer/Timer";
import {useTranslation} from "react-i18next";
import api from "../../../service/api";
import {UserContext} from "../../../context/UserContext";
import {SuccessContext} from "../../../context/SuccessContext";

function Registration(
    {
        registrationTab,
        setRegistrationTab,
        completedStep,
        setCompletedStep,
        completedStepLegal,
        setCompletedStepLegal,
    }
) {
    const [form] = Form.useForm()
    const [disable, setDisable] = useState(true);
    const {t} = useTranslation();
    const [countries, setCountries] = useState([]);
    const {setIsLoading} = useContext(UserContext);
    const countryId = Form.useWatch('country_id', form);
    const {setIsSuccess} = useContext(SuccessContext);
    const [errors, setErrors] = useState();
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        getData();
    }, []);


    const getData = async () => {
        const {data: {countries}} = await api.get('/register');
        setCountries(countries);
    };

    const handleClick = (id) => {
        setRegistrationTab(id);
        form.resetFields();
        setErrors(null);
    };

    const handleClickStep = (id) => {
        setCompletedStep(id);
    };
    const handleClickStepLegal = (id) => {
        setCompletedStepLegal(id);
    };
    const handleIdIncrease = () => {
        setCompletedStep(completedStep + 1);
    };
    const handleIdIncreaseLegal = () => {
        setCompletedStepLegal(completedStepLegal + 1);
    };


    const onFinish = async (values, isLegalForm) => {
        try {
            setIsLoading(true);
            values.is_legal = isLegalForm;
            await api.post('/register', values)
            form.resetFields();
            setIsSuccess(true);
        } catch (e) {
            setErrors(e.response?.data?.errors)
        } finally {
            setIsLoading(false);
        }
    };
    const handleSendOTP = async (isLegal) => {
        try {
            setIsLoading(true);
            const data = form.getFieldsValue();
            data.is_legal = isLegal;
            await api.post('/register/send-otp', data);
            setRemainingTime(30);
            setDisable(true);
            if (isLegal) {
                handleIdIncreaseLegal();
            } else {
                handleIdIncrease();
            }
        } catch (e) {
            setErrors(e.response?.data?.errors)
        } finally {
            setIsLoading(false);
        }
    };
    const resendOTP = async (isLegal) => {
        try {
            setIsLoading(true);
            const data = form.getFieldsValue();
            data.is_legal = isLegal;
            form.resetFields(['code']);
            setErrors(null);
            await api.post('/register/send-otp', data);
        } catch (e) {
            setErrors(e.response?.data?.errors)
        } finally {
            setIsLoading(false);
        }
    };

    const handleOTPCheck = async (isLegal) => {
        try {
            setIsLoading(true);
            const data = form.getFieldsValue();
            data.is_legal = isLegal;
            await api.post('/register/check-otp', data);
            if (isLegal) {
                handleIdIncreaseLegal();
            } else {
                handleIdIncrease();
            }
        } catch (e) {
            setErrors(e.response?.data?.errors)
        } finally {
            setIsLoading(false);
        }
    };
    const onPhoneChange = (event) => {
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!Number.isNaN(event.key) && reg.test(event.key)) || event.key === '' || event.key === '-') {
            return true
        }
        event.preventDefault();
    };

    return (
        <>
            <div className="registration-tab">
                <p
                    className={` ${registrationTab === 1 && 'active'}`}
                    onClick={() => {
                        setCompletedStepLegal(1)
                        handleClick(1, false)
                    }}>
                    {t("naturalPerson")}
                </p>
                <p
                    className={` ${registrationTab === 2 && 'active'}`}
                    onClick={() => {
                        setCompletedStep(1)
                        handleClick(2, true)
                    }}>
                    {t("legalEntity")}
                </p>
            </div>
            <div className="auth-body">

                {registrationTab === 1 ? (
                    <div className="registration-natural-person">
                        <Form onFinish={(values) => onFinish(values, false)} form={form}>
                            <div style={{display: completedStep === 1 ? 'block' : 'none'}}>

                                <div className="hidden-input">
                                    <Form.Item name="is_legal">
                                        <Input type="hidden"/>
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    className="registration-input"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("fieldRequired"),
                                        },
                                    ]}>
                                    <Input
                                        bordered={false}
                                        placeholder={t("name")}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="registration-input"
                                    name="surname"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("fieldRequired"),
                                        },
                                    ]}>
                                    <Input
                                        bordered={false}
                                        placeholder={t("surname")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='country_id'
                                    validateStatus={errors?.country_id && ("error")}
                                    help={errors?.country_id}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t("country")}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            countries?.map((country) => (
                                                <Select.Option key={country.id} value={country.id}
                                                               name={country.name}>
                                                    {country.name}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    className="registration-input"
                                    name='email'
                                    validateStatus={errors?.email && ("error")}
                                    help={errors?.email}
                                >
                                    <Input
                                        bordered={false}
                                        placeholder={t("email")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="registration-input"
                                    name="phone"
                                    validateStatus={errors?.phone && ("error")}
                                    help={errors?.phone}>
                                    <Input
                                        onKeyPress={onPhoneChange}
                                        bordered={false}
                                        placeholder={t("phoneNumber")}
                                        controls={false}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button className="auth-button"
                                            onClick={() => handleSendOTP(false)}
                                    >
                                        <Sms/> {t("sendTheCode")}
                                    </Button>
                                </Form.Item>
                            </div>
                            <div className="registration-phone-number"
                                 style={{display: completedStep === 2 ? 'block' : 'none'}}>

                                {
                                    countryId === 171 ? (
                                        <p className="registration-phone-number-text">
                                            {t("smsCodePhone")}
                                        </p>

                                    ) : (<p className="registration-phone-number-text">
                                            {t("smsCodeMail")}
                                        </p>
                                    )
                                }
                                <Form.Item name="code"
                                           validateStatus={errors?.code && ("error")}
                                           help={errors?.code}
                                >
                                    <InputCode
                                        name={"name"}
                                        label="Code Label"
                                    />
                                </Form.Item>

                                <Timer disable={disable} setDisable={setDisable} isLegal resendOTP={resendOTP}
                                       remainingTime={remainingTime} setRemainingTime={setRemainingTime}/>

                                <Form.Item>
                                    <Button className="auth-button"
                                            onClick={() => handleOTPCheck(false)}>
                                        <span>{t("confirmation")}</span>
                                    </Button>
                                </Form.Item>
                            </div>

                            <div className="registration-repeat-password"
                                 style={{display: completedStep === 3 ? 'block' : 'none'}}>
                                <div className="registration-repeat-password-container">
                                    <Form.Item
                                        name="password"
                                        validateStatus={errors?.password && ("error")}
                                        help={errors?.password}
                                    >
                                        < Input.Password
                                            placeholder={t("password")}
                                            visibilityToggle={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password_confirmation"
                                        dependencies={["password"]}
                                    >
                                        <Input.Password
                                            placeholder={t("repeatPassword")}
                                            visibilityToggle={false}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item>
                                    <Button
                                        className="auth-button"
                                        htmlType='submit'
                                    >
                                        {t("registration")}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>

                        <div className="registration-stepper">
                            <div className="stepper-wrapper">
                                <div
                                    className={`stepper-item ${completedStep === 1 ? 'completed' : "completed"}`}>
                                    <div className="step-counter"
                                         style={{
                                             pointerEvents: disable ? 'none' : 'unset'
                                         }}
                                         onClick={() => handleClickStep(1)}></div>
                                </div>
                                <div
                                    className={`stepper-item ${completedStep >= 2 ? 'completed' : null}`}>
                                    <div className="step-counter"
                                         style={{
                                             pointerEvents: disable ? 'none' : 'unset'
                                         }}
                                         onClick={() => handleClickStep(2)}></div>
                                </div>
                                <div
                                    className={`stepper-item ${completedStep >= 3 ? 'completed' : null}`}>
                                    <div className="step-counter"
                                         style={{
                                             pointerEvents: disable ? 'none' : 'unset'
                                         }}
                                         onClick={() => handleClickStep(3)}></div>
                                </div>
                            </div>
                        </div>
                    </div>


                ) : registrationTab === 2 ? (
                    <div className="registration-legal-entity registration-natural-person">
                        <Form onFinish={(values) => onFinish(values, true)} form={form}>
                            <div style={{display: completedStepLegal === 1 ? 'block' : 'none'}}>
                                <div className="hidden-input">
                                    <Form.Item name="is_legal">
                                        <Input type="hidden"/>
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    className="registration-input"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("fieldRequired"),
                                        },
                                    ]}>
                                    <Input
                                        bordered={false}
                                        placeholder={t("legalEntityName")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="registration-input"
                                    name="identification_code"
                                    validateStatus={errors?.identification_code && ("error")}
                                    help={errors?.identification_code}>
                                    <Input
                                        onKeyPress={onPhoneChange}
                                        bordered={false}
                                        placeholder={t("IdentificationNumber")}
                                        controls={false}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="registration-input"
                                    name='email'
                                    validateStatus={errors?.email && ("error")}
                                    help={errors?.email}>
                                    <Input
                                        bordered={false}
                                        placeholder={t("email")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="registration-input"
                                    name="phone"
                                    validateStatus={errors?.phone && ("error")}
                                    help={errors?.phone}>
                                    <Input
                                        onKeyPress={onPhoneChange}
                                        bordered={false}
                                        placeholder={t("phoneNumber")}
                                        controls={false}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button className="auth-button"
                                            onClick={() => handleSendOTP(true)}
                                    >
                                        <Sms/> {t("sendTheCode")}
                                    </Button>
                                </Form.Item>
                            </div>

                            <div className="registration-phone-number"
                                 style={{display: completedStepLegal === 2 ? 'block' : 'none'}}>

                                <p className="registration-phone-number-text">
                                    {t("smsCodeMail")}
                                </p>
                                <Form.Item name="code"
                                           validateStatus={errors?.code && ("error")}
                                           help={errors?.code}
                                >
                                    <InputCode
                                        name="code"
                                        label="Code Label"
                                    />
                                </Form.Item>

                                <Timer disable={disable} setDisable={setDisable} isLegal resendOTP={resendOTP}
                                       remainingTime={remainingTime} setRemainingTime={setRemainingTime}/>
                                <Button className="auth-button"
                                        onClick={() => handleOTPCheck(true)}>
                                    <span>{t("confirmation")}</span>
                                </Button>
                            </div>
                            <div className="registration-repeat-password"
                                 style={{display: completedStepLegal === 3 ? 'block' : 'none'}}>
                                <div className="registration-repeat-password-container">
                                    <Form.Item
                                        name="password"
                                        validateStatus={errors?.password && ("error")}
                                        help={errors?.password}
                                    >
                                        < Input.Password
                                            placeholder={t("password")}
                                            visibilityToggle={false}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password_confirmation"
                                        dependencies={["password"]}
                                    >
                                        <Input.Password
                                            placeholder={t("repeatPassword")}
                                            visibilityToggle={false}
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item>
                                    <Button
                                        className="auth-button"
                                        htmlType='submit'
                                    >
                                        <span>{t("registration")}</span>
                                    </Button>
                                </Form.Item>

                            </div>

                        </Form>

                        <div className="registration-stepper">
                            <div className="stepper-wrapper">
                                <div
                                    className={`stepper-item ${completedStepLegal === 1 ? 'completed' : "completed"}`}>
                                    <div className="step-counter"
                                         style={{
                                             pointerEvents: disable ? 'none' : 'unset'
                                         }}
                                         onClick={() => handleClickStepLegal(1)}></div>
                                </div>
                                <div
                                    className={`stepper-item ${completedStepLegal >= 2 ? 'completed' : null}`}>
                                    <div className="step-counter"
                                         style={{
                                             pointerEvents: disable ? 'none' : 'unset'
                                         }}
                                         onClick={() => handleClickStepLegal(2)}></div>
                                </div>
                                <div
                                    className={`stepper-item ${completedStepLegal >= 3 ? 'completed' : null}`}>
                                    <div className="step-counter"
                                         style={{
                                             pointerEvents: disable ? 'none' : 'unset'
                                         }}
                                         onClick={() => handleClickStep(3)}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : null}
            </div>
        </>
    );
}

export default Registration;

import React, {useContext, useEffect, useState} from 'react';
import CartItem from "../../components/cart/cart-item/CartItem";
import useCart from "../../hooks/useCart";
import {Checkbox, Empty, Form} from "antd";
import {UserContext} from "../../context/UserContext";
import api from "../../service/api";
import {useTranslation} from "react-i18next";
import AddressForm from "../../components/Forms/AddressForm";

function Order() {
    const {carts} = useCart();
    const {setIsLoading} = useContext(UserContext);
    const {t} = useTranslation();
    const token = localStorage.getItem('token');
    const [form] = Form.useForm();
    const [regions, setRegions] = useState([]);
    const [errors, setErrors] = useState();
    const [checked, setChecked] = useState(true);

    const headers = {
        'Authorization': `Bearer ${token}`
    };
    const handleOrder = async (address, id) => {
        try {
            setIsLoading(true);
            const response = await api.post('/orders', {
                customer_address: address,
                region_id: id
            }, {
                headers: headers
            });
            if (response.data.status === "success") {
                window.location.href = response.data.paymentUrl;
            }
        } catch (e) {
            setErrors(e.response?.data?.errors);
        } finally {
            setIsLoading(false);
        }
    };
    const handleOrderButtonClick = async () => {
        try {
            await form.validateFields();
            const address = form.getFieldValue('customer_address');
            const id = form.getFieldValue('region_id');
            await handleOrder(address, id);
        } catch (error) {
            console.error('Form validation error:', error);
        }
    };

    const getRegions = async () => {
        try {
            setIsLoading(true);
            const {data: {regions}} = await api.get('/regions');
            setRegions(regions);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeCheckbox = (e) => {
        form.resetFields();
        setChecked(e);
    }
    useEffect(() => {
        getRegions();
    }, []);
    return (
        <div className="order-container">
            <div className="header-cart-items" style={{width: "100%"}}>
                {carts?.totalItem > 0 ? (
                    <>
                        {carts?.cart.map((cart) => (
                            <CartItem key={cart.id} cart={cart}/>
                        ))}
                        <hr/>
                        <Checkbox
                            style={{width: "fit-content"}}
                            checked={checked}
                            onChange={(e) => handleChangeCheckbox(e.target.checked)}
                        >
                            წავიღებ
                        </Checkbox>
                        <AddressForm
                            errors={errors}
                            regions={regions}
                            form={form}
                            t={t}
                            onFinish={handleOrderButtonClick}
                            checked={checked}
                        />
                        <div className="header-cart-bottom">
                            <div className="header-cart-sum">
                                <p>{carts?.totalItem} {t("productTotal")}</p>
                                <p>{carts?.saleAmount.toFixed(2)}₾</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <Empty description={t("cartEmpty")}/>
                )}
            </div>
        </div>
    );
}

export default Order;

import {useTranslation} from "react-i18next";
import EventCard from "./event-card/EventCard";
import OffersCard from "./offers-card/OffersCard";
import api from "../../service/api";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";

function HomeOffers() {
    const {t} = useTranslation();
    const {setIsLoading} = useContext(UserContext);
    const [offers, setOffers] = useState(null);
    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await api.get("/main");
            const offers = response.data['offers'];
            setOffers({offers});
        } catch {
        // error
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="home-offers-container">
            {offers?.offers.length > 0 &&
                <h3>{t("offers")}</h3>
            }
            <div className="home-offers">
                <OffersCard offers={offers}/>
                {/*<div className="home-offers-second-card">*/}
                {/*    <EventCard/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default HomeOffers;

import {useContext} from 'react';
import api from "../service/api";
import {UserContext} from "../context/UserContext";
import {CartContext} from "../context/CartContext";
import {ModalContext} from "../context/ModalContext";
import {message} from "antd";
import {useTranslation} from "react-i18next";

export default function useCart() {
    const {carts, setCarts} = useContext(CartContext);
    const token = localStorage.getItem('token');
    const {setIsLoading, userToken} = useContext(UserContext);
    const {openModal} = useContext(ModalContext);
    const {t} = useTranslation();

    const headers = {
        'Authorization': `Bearer ${token}`
    };
    const removeFromCart = async (id) => {
        try {
            setIsLoading(true);
            const response = await api.delete(`/carts/${id}`, {headers});
            if (response.data.status === 'success') {
                message.success(t("removedFromCart"));
               await updateData();
            }
        } catch {
            // handle error
        } finally {
            setIsLoading(false);
        }
    };

    const removeAllItemsFromCart = async () => {
        try {
            setIsLoading(true);
            const response = await api.delete('/carts/clean', {headers});
            if (response.data.status === 'success') {
               await updateData();
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const addToCart = async (data) => {
        const {product_id} = data;
        if (userToken) {
            const cartItemExists = carts.cart.find((cartItem) => cartItem.product.id === product_id);
            if (cartItemExists) {
                data.quantity += cartItemExists.quantity;
            }
            setIsLoading(true);
            try {
                const res = await api.post('/carts', data, {headers});
                if (res.data.status === 'success') {
                    await updateData();
                    message.success(t("addedToCart"));
                }
            } catch (e) {
                handleError(e);
            } finally {
                setIsLoading(false);
            }
        } else {
            openModal();
        }
    };

    const handleError = (error) => {
        if (error.response.status === 422) {
            message.error(error.response.data.errors.quantity);
        } else {
            message.error(t("anErrorOccurred"));
        }
    };


    const updateQuantity = async (id, {quantity}) => {
        try {
            setIsLoading(true);
            const res = await api.put(`/carts/${id}`, {quantity}, {headers});
            setIsLoading(false);
            if (res.data.status === 'success') {
                await updateData();
            }
        } catch (e) {
            if (e.response.status === 422) {
                message.error(e.response.data.errors.quantity);
            } else {
                message.error(t("anErrorOccurred"));
            }
        } finally {
            setIsLoading(false);
        }
    };

    const updateData = async () => {
        if (!userToken) {
            return;
        }
        try {
            setIsLoading(true);
            const res = await api.get(`/carts`, {headers: headers});
            setCarts(res.data);
        } catch (e) {
            message.error(t("anErrorOccurred"));
        } finally {
            setIsLoading(false);
        }
    };

    return {carts, removeFromCart, addToCart, updateQuantity, updateData, removeAllItemsFromCart};
}

import React, { useContext, useState } from 'react';
import {Button, Form, Input, message} from 'antd';
import { useTranslation } from 'react-i18next';
import api from '../../../service/api';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { ModalContext } from '../../../context/ModalContext';

function Login() {
    const [isLoginError, setIsLoginError] = useState(false);
    const [isRecoverError, setIsRecoverError] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [recoverError, setRecoverError] = useState('');
    const [tab, setTab] = useState(1);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { setUserToken, setIsLoading } = useContext(UserContext);
    const { closeModal } = useContext(ModalContext);
    const [form] = Form.useForm();

    const handleLoginError = (error, message) => {
        if (error.response && (error.response.status === 401 || error.response.status === 422)) {
            setLoginError(message);
            setIsLoginError(true);
        }
    };

    const onFinish = async (values) => {
        try {
            setIsLoading(true);
            setIsLoginError(false);
            const { data } = await api.post('/login', values);
            localStorage.setItem('token', data.token);
            form.resetFields();
            setUserToken(data.token);
            closeModal();
            navigate(`/${i18n.language}/profile`);
        } catch (e) {
            handleLoginError(e, t("userOrPassIncorrect"));
        } finally {
            setIsLoading(false);
        }
    };

    const passwordRecover = async (values) => {
        try {
            setIsLoading(true);
            const { data } = await api.post('/forgot-password', values, {
                headers: {
                    'Password-reset': true,
                },
            });
            form.resetFields();
            if (data.status === 'success') {
                setRecoverError('');
                setIsRecoverError(false);
                message.success(data.email);
            }
        } catch (e) {
            setIsRecoverError(true);
            setRecoverError(e.response?.data?.message);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="auth-body login-container">
            {tab === 1 ? (
                <>
                    <Form onFinish={onFinish} form={form}>
                        <Form.Item
                            className="registration-input"
                            name="email"
                            validateStatus={isLoginError ? 'error' : ''}
                            help={isLoginError ? loginError : ''}
                            rules={[
                                {
                                    required: true,
                                    message: t("fieldRequired"),
                                },
                            ]}
                        >
                            <Input placeholder={t('email')} />
                        </Form.Item>
                        <Form.Item
                            className="registration-input"
                            name="password"
                            validateStatus={isLoginError ? 'error' : ''}
                            help={isLoginError ? loginError : ''}
                            rules={[
                                {
                                    required: true,
                                    message: t("fieldRequired"),
                                },
                            ]}
                        >
                            <Input.Password placeholder={t('password')} visibilityToggle={false} />
                        </Form.Item>
                        <Form.Item>
                            <Button className="auth-button" htmlType="submit">
                                {t('logIn')}
                            </Button>
                        </Form.Item>
                    </Form>
                    <button onClick={() => setTab(2)} className="recovery-tab">
                        {t('recoverPassword')}
                    </button>
                </>
            ) : tab === 2 ? (
                <>
                    <Form onFinish={passwordRecover} form={form}>
                        <Form.Item
                            className="registration-input"
                            name="email"
                            validateStatus={isRecoverError ? 'error' : ''}
                            help={isRecoverError ? recoverError : ''}
                            rules={[
                                {
                                    required: true,
                                    message: t("fieldRequired"),
                                },
                            ]}
                        >
                            <Input placeholder={t('email')} />
                        </Form.Item>
                        <Form.Item>
                            <Button className="auth-button" htmlType="submit">
                                {t('recoverPassword')}
                            </Button>
                        </Form.Item>
                    </Form>
                    <button onClick={() => setTab(1)} className="login-tab">
                        {t('logIn')}
                    </button>
                </>
            ) : null}
        </div>
    );
}

export default Login;

import React, {useContext, useEffect, useState} from 'react';
import {ReactComponent as Edit} from "../../assets/images/svg/edit.svg";
import {ReactComponent as Logout} from "../../assets/images/svg/logout.svg";
import {ReactComponent as Confirm} from "../../assets/images/svg/confirm.svg";
import {useTranslation} from "react-i18next";
import Orders from "../orders/Orders";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/UserContext";
import {CartContext} from "../../context/CartContext";
import api from "../../service/api";
import ChangePassword from "./change-password /ChangePassword";
import {Button, Form, Input, Select} from "antd";

const {Option} = Select;

function Profile() {
    const [disabled, setDisabled] = useState(true);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {setUserToken, setIsLoading} = useContext(UserContext);
    const {setCarts} = useContext(CartContext);
    const [customer, setCustomer] = useState(null);
    const token = localStorage.getItem('token');
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const handleClickConfirm = () => {
        setDisabled(true);
        handleProfileUpdate();
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setUserToken(null);
        navigate(`/${i18n.language}/`);
        setCarts(null);
    };

    const getCustomer = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/customer', {headers});
            setCustomer(response.data);
            form.setFieldsValue({
                name: response.data.customer.name,
                surname: response.data.customer.surname,
                email: response.data.customer.email,
                phone: response.data.customer.phone,
                is_legal: response.data.customer.is_legal,
                country_id: response.data.customer.country_id,
                identification_code: response?.data.customer.identification_code
            });
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCustomer();
    }, []);

    const onPhoneChange = (event) => {
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!Number.isNaN(event.key) && reg.test(event.key)) || event.key === '' || event.key === '-') {
            return true
        }
        event.preventDefault();
    };

    const handleProfileUpdate = async () => {
        const data = form.getFieldsValue();
        setIsLoading(true);
        try {
            await api.put('/customer', data, {headers});
            setIsLoading(false);
        } catch (e) {
            setError(e.response.data.message)
        }finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="profile-container">
            <div className="profile-title">
                <span>{t("profile")}</span>
                <hr/>
            </div>
            <Form layout="vertical" className="profile-form" form={form} disabled={disabled}>
                <Form.Item
                    className="isLegal"
                    name="is_legal"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={customer?.customer.is_legal === 0 ? `${t("name")}` : `${t("companyName")}`}
                    name="name"
                >
                    <Input/>
                </Form.Item>
                {customer?.customer.is_legal === 0 ?
                    (
                        <Form.Item
                            label={t("surname")}
                            name="surname"
                        >
                            <Input/>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label={t("IdentificationNumber")}
                            name="identification_code"
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    )}
                {customer?.customer.is_legal === 0 ?
                    <Form.Item
                        name='country_id'
                        label={t("country")}
                    >
                        <Select
                            showSearch
                            className={'profile-select'}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                customer.countries.map((country) =>
                                    <Option key={country.id} value={country.id} children={null}
                                            name={country.name}>
                                        {country.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Form.Item> : null
                }
                <Form.Item
                    label={t("email")}
                    name="email"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={t("phoneNumber")}
                >
                    <Input
                        onKeyPress={onPhoneChange}
                        bordered={false}
                    />
                </Form.Item>

            </Form>
            <div className="profile-btn">
                {
                    disabled ?
                        <Button onClick={() => setDisabled(false)}><Edit/>{t("edit")}</Button>
                        : <Button onClick={handleClickConfirm}><Confirm/>{t("confirm")}</Button>
                }
                <Button onClick={() => setOpen(true)}><Edit/>{t("changePassword")}</Button>
                <Button onClick={handleLogout}><Logout/>{t("logOutProfile")}</Button>
            </div>
            <Orders/>
            <ChangePassword open={open} close={() => setOpen(false)}/>
        </div>
    );
}

export default Profile;

import {useContext, useEffect, useState} from 'react';
import {UserContext} from "../context/UserContext";
import api from "../service/api";

function usePages(id) {
    const [pageData, setPageData] = useState(null);
    const [error, setError] = useState(null);
    const {setIsLoading} = useContext(UserContext);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await api.get(`/pages/${id}`);
                setPageData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [id]);

    return { pageData, error };
}

export default usePages;

import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {UserContext} from "../../context/UserContext";
import api from "../../service/api";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailure from "./PaymentFailure";
import useCart from "../../hooks/useCart";

const Payment = () => {
  const {removeAllItemsFromCart} = useCart();
  const [data, setData] = useState(null);

  const token = localStorage.getItem('token');

  const headers = {
    'Authorization': `Bearer ${token}`
  };

  const {id} = useParams();

  const {setIsLoading} = useContext(UserContext);
  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/orders/check-payment/${id}`, {headers});
      if (res.data.status === 'success'){
        setData(res.data)
        if (res.data.isPaid) {
         await removeAllItemsFromCart();
        }
      }
    } catch {

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return data && ((data?.isPaid === true) ? <PaymentSuccess /> : <PaymentFailure />)
};

export default Payment;

import FooterLogo from "../../assets/images/png/region_logo.png";
import {ReactComponent as Facebook} from "../../assets/images/svg/facebook.svg";
import {ReactComponent as Instagram} from "../../assets/images/svg/instagram.svg";
import {ReactComponent as Linkedin} from "../../assets/images/svg/linkedin.svg";
import {ReactComponent as Tiktok} from "../../assets/images/svg/tiktok.svg";
import {ReactComponent as Youtube} from "../../assets/images/svg/youtube.svg";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Map from "../../assets/images/png/mini-map.png";
import {useTranslation} from "react-i18next";
import api from "../../service/api";

function Footer() {
    const [contact, setContacts] = useState(null);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await api.get('/contact');
        const contactInfo = response.data['contact-info'];
        setContacts({contactInfo});
    };
    return (
        <footer>
            <div className="footer-container">

                <div className="footer-list">

                    <div className="footer-mobile">
                        <div className="footer-first-container">
                            <ul>
                                <li className='footer-list-item footer-logo'>
                                    <Link to={`/${i18n.language}/`}><img src={FooterLogo}
                                                                         alt="footer-logo"/></Link>
                                </li>
                            </ul>
                            <div className="footer-second-container">
                                <ul className="footer-menu">

                                    <li className='footer-list-item'>
                                        <Link to={`/${i18n.language}/`}>{t("main")}</Link>
                                    </li>
                                    <li className='footer-list-item'>
                                        <Link to={`/${i18n.language}/news`}>{t("news")}</Link>
                                    </li>
                                    <li className='footer-list-item'>
                                        <Link
                                            to={`/${i18n.language}/about-us`}>{t("aboutUs")}</Link>
                                    </li>

                                </ul>

                                <ul className="footer-menu">

                                    <li className='footer-list-item'>
                                        <Link to={`/${i18n.language}/municipality`}
                                        >{t("municipality")}</Link>
                                    </li>
                                    <li className='footer-list-item'>
                                        <Link to={`/${i18n.language}/terms-conditions`}
                                        >{t("termsConditions")}</Link>
                                    </li>
                                    <li className='footer-list-item'>
                                        <Link
                                            to={`/${i18n.language}/contact`}>{t("contact")}</Link>
                                    </li>

                                </ul>
                            </div>
                            <ul className="footer-phone-number">
                                <a href={`tel:${contact?.contactInfo.phone}`}>
                                    <li>{t("contactUs")}<h2>{contact?.contactInfo.phone}</h2></li>
                                </a>
                                <ul className="footer-social-media">
                                    {contact?.contactInfo.facebook &&
                                        <li>
                                            <a href={contact?.contactInfo.facebook} target="_blank"
                                               rel="noreferrer"><Facebook/>
                                            </a>
                                        </li>
                                    }
                                    {contact?.contactInfo.instagram &&
                                        <li>
                                            <a href={contact?.contactInfo.instagram} target="_blank"
                                               rel="noreferrer"><Instagram/>
                                            </a>
                                        </li>
                                    }
                                    {contact?.contactInfo.linkedin &&
                                        <li>
                                            <a href={contact?.contactInfo.linkedin} target="_blank"
                                               rel="noreferrer"><Linkedin/>
                                            </a>
                                        </li>
                                    }
                                    {contact?.contactInfo.tiktok &&
                                        <li>
                                            <a href={contact?.contactInfo.tiktok} target="_blank"
                                               rel="noreferrer"><Tiktok/>
                                            </a>
                                        </li>
                                    }
                                    {contact?.contactInfo.youtube &&
                                        <li>
                                            <a href={contact?.contactInfo.youtube} target="_blank"
                                               rel="noreferrer"><Youtube/>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </ul>
                        </div>

                    </div>
                    <div className="footer-mobile">

                        <ul>
                            <Link to={`/${i18n.language}/contact`}>
                                <li className='footer-list-item footer-map'>
                                    <img src={Map} alt="map"/>
                                </li>
                            </Link>
                        </ul>

                        <ul className="footer-address">
                            <li className='footer-list-item footer-item'>
                                {contact?.contactInfo.address}
                            </li>
                            <li className="footer-list-item">
                                <a href={`mailto:${contact?.contactInfo.email}`}>{contact?.contactInfo.email}</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
import React, {useEffect, useState} from "react";
import api from "../../service/api";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Keyboard, Scrollbar, Navigation, Pagination} from "swiper";

function MobileSlider() {
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const {data: {slides}} = await api.get('/slides');
        setSlides(slides);
    };

    function addPrefixToUrl(url) {
        if (!url) {
            return null;
        }
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = `https://www.${url}`;
        }
        return url;
    }

    return (
        <div className="mobile-slider">
            {slides.length > 0 && <Swiper
                slidesPerView={1}
                centeredSlides={false}
                slidesPerGroupSkip={1}
                spaceBetween={16}
                grabCursor={true}
                keyboard={{
                    enabled: true,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                className="mySwiper"
            >
                {slides.filter(slide => Boolean(slide.title)).map((slide, index) => (
                    <SwiperSlide
                        key={index}
                    >
                        <a href={addPrefixToUrl(slide?.url)} target="_blank">
                            <div className="gradient"/>
                        </a>
                        <img src={`${IMAGE_URL_BASE}${slide.image}`} alt={slide.title}/>
                        <div className="mobile-slider-text">
                            <h3>{slide.title}</h3>
                            <p>{slide.short_description}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>}
        </div>
    );
}

export default MobileSlider;
import {ReactComponent as Production} from "../../../assets/images/svg/production.svg";
import {ReactComponent as Arrow} from "../../../assets/images/svg/arrow.svg";
import {ReactComponent as Minus} from "../../../assets/images/svg/minus.svg";
import {ReactComponent as Plus} from "../../../assets/images/svg/plus.svg";
import React, {useRef, useState} from "react";
import {Collapse} from "antd";
import clickOutside from "../../../components/backdrop/backdrop";
import {useTranslation} from "react-i18next";

function Dropdown({categories, handleFilter}) {
    const {t} = useTranslation();
    const [rotate, setRotate] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const {Panel} = Collapse;
    const wrapperRef = useRef(null);

    const handleClick = () => {
        setRotate(rotate => !rotate);
        setIsActive(!isActive);
    };

    const handleFilterAndClose = (id, name) => {
        handleFilter(id, name);
        setIsActive(false);
    };

    clickOutside(wrapperRef, setIsActive, setRotate);

    return (
        <div ref={wrapperRef} className="store-dropdown">
            <div
                className={rotate ? 'dropdown-button-rotate' : 'dropdown-button'}
                onClick={handleClick}
            >
                    <span className="dropdown-svg">
                        <Production/>
                        {t("production")}
                    </span>
                <span className="dropdown-arrow"><Arrow/></span>
            </div>

            <div className={`dropdown-content ${isActive ? 'show' : null}`}>
                <div className="dropdown-items">

                    <Collapse
                        expandIconPosition={"end"}
                        ghost
                        expandIcon={({isActive}) => isActive ? <Plus/> : <Plus/>}
                        accordion={true}
                    >
                        {categories?.map((category) => (
                            <Panel key={category.id} header={category.name}
                                   onClick={() => handleFilterAndClose(category.id, category.name)}>
                                {/*{category.children.map((childCategory) => (*/}
                                {/*    <div className="dropdown-item-category" key={childCategory.id}>*/}
                                {/*        <span>{childCategory.name}</span>*/}
                                {/*    </div>*/}
                                {/*))}*/}
                            </Panel>
                        ))}
                    </Collapse>
                </div>

            </div>

        </div>

    );
}

export default Dropdown;

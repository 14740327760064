import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function NotFound() {
    const {t, i18n} = useTranslation();

    return (
        <div className="page-not-found">
            <div className="not-found-block">
                <h1>404</h1>
                <h3>{t("pageNotFound")}</h3>
                <Link to={`/${i18n.language}/`}>
                    <button>{t("goToMain")}</button>
                </Link>
            </div>
        </div>
    );
}

export default NotFound;
import {useNavigate, useParams} from "react-router-dom";
import api from "../../../service/api";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


const defaultDetailPostData = {
    post: null,
    similarPosts: [],
}

export function useData() {
    const {id} = useParams();
    const {i18n} = useTranslation();
    const [detailPosts, setDetailPosts] = useState(defaultDetailPostData);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getData(id);
        if (detailPosts?.post?.title === "") {
            navigate(`/${i18n.language}/news`);
        }
    }, [id, detailPosts?.post?.title]);

    async function getData(id) {
        try {
            setIsLoading(true);
            const {data} = await api.get(`/posts/${id}`);
            setDetailPosts(data);
            setIsLoading(false);
        } catch (e) {
            setError(e);
            setIsLoading(false);
            navigate("/404");
        }
    }

    return {
        post: detailPosts.post,
        similarPosts: detailPosts.similarPosts,
        isLoading,
        error,
    }
}
import {ReactComponent as Addition} from "../../assets/images/svg/addition.svg";
import {ReactComponent as Subtraction} from "../../assets/images/svg/subtraction.svg";
import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as Add} from "../../assets/images/svg/shopping-1.svg";
import {ReactComponent as Percent} from "../../assets/images/svg/percentw.svg";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as Arrow} from "../../assets/images/svg/arrow.svg";
import api from "../../service/api";
import {UserContext} from "../../context/UserContext";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import useCart from "../../hooks/useCart";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Thumbs} from "swiper";
import Card from "../../components/card/Card";
import {Image} from "antd";

function Product() {
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(null);
    const {setIsLoading} = useContext(UserContext);
    const {id} = useParams();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const {addToCart} = useCart();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const getData = async (id) => {
        try {
            setIsLoading(true);
            const response = await api.get(`/products/${id}`);
            const productDetails = response.data['product'];
            const similarProducts = response.data['similarProducts'];
            setProduct({productDetails, similarProducts});
        } catch (e) {
            //todo დასამატებელი ერორის შემთხვევაში, /product/:id
            navigate("/404");
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        getData(id);
        if (product?.productDetails.name === "") {
            navigate(`/${i18n.language}/shop`);
        }
    }, [id, product?.productDetails.name]);

    const productData = {
        product_id: product?.productDetails.id,
        quantity: quantity
    };

    const subtraction = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    };

    return (
        <div className="product-container">
            <div className="product">
                <div className="back-to-store">
                    <Link to={`/${i18n.language}/shop`}>
                        <span><Arrow/>{t("back")}</span>
                    </Link>
                </div>
                <div className="product-image">

                    <Swiper
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                    >
                        {product?.productDetails.gallery.map((image) => (
                            <SwiperSlide key={image.id}>
                                <Image src={`${IMAGE_URL_BASE}${image.url}`} alt={image.name} height="100%"/>
                                {product?.productDetails?.price > product?.productDetails?.sell_price &&
                                    <div className="product-sale">
                                        <Percent/>
                                    </div>
                                }
                            </SwiperSlide>

                        ))
                        }

                    </Swiper>

                    <Swiper
                        slidesPerView="auto"
                        breakpoints={{
                            1024: {
                                direction: 'vertical',
                                slidesPerView: 6
                            },
                        }}
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="thumb-swiper"
                    >
                        {product?.productDetails.gallery.map((image) => (
                            <SwiperSlide key={image.id}>
                                <img src={`${IMAGE_URL_BASE}${image.url}`} alt={image.name}/>
                            </SwiperSlide>
                        ))
                        }
                    </Swiper>

                </div>

                <div className="product-info">

                    <div className="product-info-cellar-name">

                        <Link to={(`/${i18n.language}/online-shop/${product?.productDetails.company.id}`)}>
                            <span>
                            <img src={`${IMAGE_URL_BASE}${product?.productDetails.company.featured_image}`}
                                 alt={product?.productDetails.name}/>
                                {product?.productDetails.company.name}
                            </span>
                        </Link>
                    </div>

                    <div className="product-info-title">
                        <h2>{product?.productDetails.name}</h2>
                    </div>
                    <div className="product-info-price-quantity">
                        <div className="product-info-price-container">
                            <span>{t("price")}</span>
                            <div className="product-info-price">
                                <p>{product?.productDetails.sell_price}₾</p>
                                {product?.productDetails.price > product?.productDetails.sell_price &&
                                    <p>{product?.productDetails.price}₾</p>
                                }
                            </div>
                        </div>
                        <div className="product-info-quantity-container">
                            <p>{t("quantity")}</p>
                            <div className="product-info-quantity">
                                <span onClick={subtraction}><Subtraction/></span>
                                <p>{quantity}</p>
                                <span onClick={() => setQuantity(quantity + 1)}><Addition/></span>
                            </div>
                        </div>

                    </div>

                    <button className="product-info-add" onClick={() => addToCart(productData)}>
                        <span><Add/></span>
                        {t("addToCard")}
                    </button>

                    <div className="product-description"
                         dangerouslySetInnerHTML={{__html: product?.productDetails.description}}/>
                </div>

            </div>

            <div className="product-cards-title">
                <h2>{t("similarProducts")}</h2>
                <hr/>
            </div>
            <div className="product-cards">
                <Card data={product?.similarProducts}/>
            </div>
        </div>
    );
}

export default Product;

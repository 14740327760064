import {ReactComponent as CalendarToday, ReactComponent as EventIcon} from "../../assets/images/svg/calendar-today.svg";
import {Empty, Pagination} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as SearchIcon} from "../../assets/images/svg/search.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ReactComponent as CalendarFuture} from "../../assets/images/svg/calendar-future.svg";
import MaskedInput from 'react-text-mask';
import Dropdown from "../../components/dropdown/dropdown";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../context/UserContext";
import api from "../../service/api";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {ReactComponent as NewsIcon} from "../../assets/images/svg/news.svg";
import {ReactComponent as AnnouncementIcon} from "../../assets/images/svg/announcement.svg";
import {ReactComponent as PercentIcon} from "../../assets/images/svg/percent.svg";
import {ReactComponent as All} from "../../assets/images/svg/all.svg";
import moment from "moment";


function News() {
    const {t, i18n, i18n: {language}} = useTranslation();
    const navigate = useNavigate();
    const {setIsLoading} = useContext(UserContext);
    const [posts, setPosts] = useState(null);
    const [categories, setCategories] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const [style, setStyle] = useState({
        width: 0, left: 0
    });
    const ref = useRef(new Array(categories?.postCategories.length).fill(null));

    useEffect(() => {
        if (!ref.current.every(Boolean)) return;
        setStyle({
            left: ref.current[activeTab]?.offsetLeft,
            width: ref.current[activeTab]?.clientWidth
        });
    }, [activeTab, setStyle, categories]);

    const icons = {
        5: <All/>,
        1: <NewsIcon/>,
        2: <EventIcon/>,
        3: <AnnouncementIcon/>,
        4: <PercentIcon/>
    };

    const getData = async (categoryId) => {
        try {
            setIsLoading(true);
            const endpoint = '/posts';
            const params = {page: currentPage, keyword: searchQuery, start_date: startDate, end_date: endDate};
            if (categoryId !== 0) {
                params.post_category_id = categoryId;
            }
            const response = await api.get(endpoint, {params});
            const data = response.data;
            setPosts(data);
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
        return true;
    };
    const getCategories = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/post-categories');
            const postCategories = response.data['postCategories'];
            postCategories.unshift({id: 5, name: `${t("all")}`});
            postCategories.forEach((_, index) => postCategories[index].key = index)
            setCategories({postCategories});
        } catch {
            // error
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData(activeTab);
        getCategories();
    }, [activeTab, currentPage, startDate, endDate]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDateChange = (date, type) => {
        if (type === 'start') {
            date = date ? new Date(moment(date).add('hours')) : null;
            setStartDate(date);
        } else if (type === 'end') {
            date = date ? new Date(date.getTime() + 86399999) : null;
            setEndDate(date);
        }
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        const adjustedStart = start ? new Date(moment(start).add('hours')) : null;
        const adjustedEnd = end ? new Date(end.getTime() + 86399999) : null;
        setStartDate(adjustedStart);
        setEndDate(adjustedEnd);
    };

    return (
        <div className="news-container">
            <div className="news-filter">
                <div className="news-filter-desktop">
                    <div className="news-filter-list">
                        <div className="active-tab-border" style={style}/>
                        {
                            categories?.postCategories.map(({id, name, key}, index) => (
                                <span key={id} ref={el => ref.current[key] = el}
                                      className={` ${activeTab === index && 'active'}`}
                                      onClick={() => setActiveTab(key)}>
                                    {icons[id]}{name}
                                </span>
                            ))
                        }
                    </div>
                </div>


                <div className="news-filter-mobile">
                    <Dropdown
                        categories={categories}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />
                </div>


                <div className="news-date-search">
                    <ol>
                        <li className="news-date-search-mobile news-date-search-date">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selectsRange={true}
                                withPortal
                                customInput={<MaskedInput
                                    mask={[/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
                                />}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={onChange}
                            />
                            <span className="news-today-calendar"><CalendarToday/></span>
                        </li>
                        <li className={`news-date-search news-start-date${'-' + language}`}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={(date) => handleDateChange(date, 'start')}
                                selectsStart
                                endDate={endDate}
                                customInput={<MaskedInput
                                    mask={[/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
                                />}
                                placeholderText="dd/mm/yyyy-დან"
                            />
                            <span className="news-today-calendar"><CalendarToday/></span>
                        </li>
                        <li className={`news-date-search news-end-date${'-' + language}`}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={endDate}
                                startDate={startDate}
                                onChange={(date) => handleDateChange(date, 'end')}
                                selectsEnd
                                minDate={startDate}
                                customInput={<MaskedInput
                                    mask={[/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
                                />}
                                placeholderText="dd/mm/yyyy-მდე"
                            />
                            <span className="news-today-calendar"><CalendarFuture/></span>
                        </li>

                        <li
                            className="news-date-search news-search-input"
                        >
                            <input placeholder={t("search")}
                                   onKeyDown={event => {
                                       if (event.key === 'Enter') {
                                           getData(activeTab);
                                       }
                                   }}
                                   onChange={(event) => setSearchQuery(event.target.value)}
                                   value={searchQuery}
                            />
                            <button className="news-search-icon" onClick={() => getData(activeTab)}><SearchIcon/>
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="news-cards">
                {
                    posts?.data.length === 0 ? (
                        <Empty description={t("NoData")}/>
                    ) : (
                        posts?.data.filter(post => Boolean(post.title)).map((post) => (
                            <div className="news-card" key={post.id}>
                                <div className="news-card-image">
                                    <img src={`${IMAGE_URL_BASE}${post.featured_image}`} alt=""
                                         onClick={() => navigate(`/${i18n.language}/news/${post.id}`)}/>
                                </div>
                                <div className="news-card-title">
                                    {post.publish_date}
                                    <p>
                                        {icons[post.category.id]}
                                        {post.category.name}
                                    </p>
                                </div>
                                <h2>{post.title}</h2>
                                <div className="news-card-text">
                                    <p>

                                        {post.short_description}
                                    </p>
                                </div>
                            </div>
                        ))
                    )
                }

            </div>
            <div className="news-pagination">
                <Pagination current={posts?.meta.current_page} total={posts?.meta.total}
                            defaultPageSize={8} onChange={handlePageChange}/>
            </div>
        </div>
    );
}

export default News;

import React, {useContext, useState} from "react";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import Registration from "./registration/Registration";
import Login from "./login/Login";
import {ReactComponent as Success} from "../../assets/images/svg/success-popup.svg";
import {ModalContext} from "../../context/ModalContext";
import {SuccessContext} from "../../context/SuccessContext";

function Auth() {
    const [activeTab, setActiveTab] = useState(1);
    const [registrationTab, setRegistrationTab] = useState(1);
    const [completedStepLegal, setCompletedStepLegal] = useState(1);
    const [completedStep, setCompletedStep] = useState(1);
    const {t} = useTranslation();
    const {isOpen, closeModal} = useContext(ModalContext);
    const {isSuccess, setIsSuccess} = useContext(SuccessContext);

    const handleClickClose = () => {
        closeModal();
        setCompletedStep(1);
        setCompletedStepLegal(1);
        setIsSuccess(false);
    };


    const handleClickAuthorization = () => {
        setActiveTab(2);
        setCompletedStep(1);
        setCompletedStepLegal(1);
    };


    return (
        <>
            <Modal className="auth-modal" open={isOpen} onCancel={handleClickClose} footer={false}>

                {
                    isSuccess ? (
                            <div className="success-popup">
                                <div className="success-popup-content">
                                    <div className="success-popup-svg"></div>
                                    <div className="success-popup-text">
                                        <span><Success/></span>
                                        <span>{t("successRegistration")}</span>
                                    </div>
                                    <div className="success-popup-button" onClick={handleClickClose}>
                                        <span>{t("close")}</span></div>
                                </div>
                            </div>
                        ) :

                        <div className="auth-container">
                            <div className="auth">

                                <div className="auth-header">
                                    <div className="auth-title">
                                        <p
                                            onClick={() => setActiveTab(1)}
                                            className={` ${activeTab === 1 && 'active'}`}
                                        >
                                            {t("registration")}
                                        </p>
                                        <span>/</span>
                                        <p
                                            onClick={handleClickAuthorization}
                                            className={` ${activeTab === 2 && 'active'}`}
                                        >
                                            {t("authorization")}
                                        </p>
                                    </div>
                                </div>
                                {
                                    activeTab === 1 ? (
                                        <Registration
                                            registrationTab={registrationTab}
                                            setRegistrationTab={setRegistrationTab}
                                            completedStep={completedStep}
                                            setCompletedStep={setCompletedStep}
                                            completedStepLegal={completedStepLegal}
                                            setCompletedStepLegal={setCompletedStepLegal}
                                        />
                                    ) : activeTab === 2 ? (
                                        <Login/>
                                    ) : null
                                }
                            </div>
                        </div>
                }
            </Modal>

        </>
    );
}

export default Auth;

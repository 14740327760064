import React from 'react';
import {ReactComponent as NewsIcon} from "../../../assets/images/svg/news.svg";
import {ReactComponent as EventIcon} from "../../../assets/images/svg/calendar.svg";
import {ReactComponent as AnnouncementIcon} from "../../../assets/images/svg/announcement.svg";
import {ReactComponent as PercentIcon} from "../../../assets/images/svg/percent.svg";
import {Link} from "react-router-dom";
import {IMAGE_URL_BASE} from "../../../utils/url.helper";

const icons = {
    1: <NewsIcon/>,
    2: <EventIcon/>,
    3: <AnnouncementIcon/>,
    4: <PercentIcon/>
}

function NewsCard({posts}) {

    return (
        <>
            {posts?.featuredPosts.filter(post => Boolean(post.title)).map((post) => (
                <Link to={(`news/${post.id}`)} key={post.id}>
                    <div
                        className="home-news-card"
                    >
                        <img src={`${IMAGE_URL_BASE}${post.featured_image}`} alt={post.title}/>
                        <div className="home-news-card-title">
                            <p>{post.publish_date}</p>
                            <p>
                                {icons[post.category.id] || <NewsIcon/>}
                                {post.category.name}
                            </p>
                        </div>
                        <h2>{post.title}</h2>
                        <div className="home-news-card-text">
                            <p>
                                {post.short_description}
                            </p>
                        </div>
                    </div>
                </Link>
            ))}
        </>
    );
}

export default NewsCard;

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import translationKA from "./ka/translation.json";
import translationEN from "./en/translation.json";
import translationRU from "./ru/translation.json";


const resources = {
    ka: {
        translation: translationKA
    },
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    }
}

i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
})
i18n.use(LanguageDetector).use(initReactI18next).init({
    supportedLngs: ['ka', 'en', 'ru'],
    whitelist: ['ka', 'en', 'ru'],
    fallbackLng: 'ka',
    resources,
    detection: {
        caches: ['localStorage'],
        order: ['path'],
        lookupFromPathIndex: 0,
        checkWhitelist: true
    },
    interpolation: {
        escapeValue: false,
        formatSeparator: '.'
    },
})
export default i18n;
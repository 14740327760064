import React from 'react';
import RightLeaf from "../../../assets/images/png/bottom-right-leaf.png";
import {ReactComponent as ArrowForward} from "../../../assets/images/svg/arrow-forward.svg";
import {useTranslation} from "react-i18next";


function EventCard() {
    const {t} = useTranslation();

    return (
        <>
            <div className="home-offers-registration">
                <h2>{t("registration")}</h2> <ArrowForward/>
            </div>
            <div className="home-offers-right-leaf">
                <img src={RightLeaf} alt=""/>
            </div>
            <div className="home-offers-event">
                <div className="event-date">
                    <h1>22</h1>
                    <p>ოქტომბერი</p>
                </div>
                <div className="event-details">
                    <div className="event-time">
                        15:00
                    </div>
                    <div className="event-locations">
                        <h3>ბოლნისი</h3><h3>გურჯაანი</h3><h3>ყვარელი</h3>
                    </div>
                </div>
            </div>
            <div className="home-offers-event">
                <div className="event-date">
                    <h1>23</h1>
                    <p>ოქტომბერი</p>
                </div>
                <div className="event-details">
                    <div className="event-time">
                        15:00
                    </div>
                    <div className="event-locations">
                        <h3>ბოლნისი</h3><h3>გურჯაანი</h3><h3>ყვარელი</h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventCard;
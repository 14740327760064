import React from 'react';
import {Form, Input, Select,} from 'antd';
import {useTranslation} from "react-i18next";


function AddressForm({form, regions, onFinish, errors, checked}) {
    const {t} = useTranslation();


    return (
        <Form layout="vertical" form={form} disabled={checked} style={{width: "320px"}}>
            <Form.Item
                name='region_id'
                validateStatus={errors?.region_id && ("error")}
                help={errors?.region_id}
                label={t('region')}
            >
                <Select
                    placeholder={t("region")}
                    optionFilterProp="children"
                    allowClear
                >
                    {
                        regions?.map((region) => (
                            <Select.Option key={region.id} value={region.id}
                                           name={region.name}>
                                {region.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <Form.Item
                className="address-input"
                validateStatus={errors?.customer_address && ("error")}
                help={errors?.customer_address}
                name="customer_address"
                label={t('address')}
            >
                <Input placeholder={t('address')} />
            </Form.Item>
            <button type="primary" className="header-cart-button pay-btn" onClick={() => onFinish(regions.id)}>
                {t('pay')}
            </button>
        </Form>
    );
}

export default AddressForm;

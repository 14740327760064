import React from "react";
import {ReactComponent as NewsIcon} from "../../assets/images/svg/news.svg";
import {ReactComponent as EventIcon} from "../../assets/images/svg/calendar.svg";
import {ReactComponent as AnnouncementIcon} from "../../assets/images/svg/announcement.svg";
import {ReactComponent as PercentIcon} from "../../assets/images/svg/percent.svg";
import {useData} from "../../pages/news-detail/hooks/useData";
import {Link} from "react-router-dom";
import {IMAGE_URL_BASE} from "../../utils/url.helper";
import {useTranslation} from "react-i18next";

const icons = {
    1: <AnnouncementIcon/>,
    2: <EventIcon/>,
    3: <NewsIcon/>,
    4: <PercentIcon/>
};

function SimilarNews() {
    const {post, similarPosts} = useData();
    const {i18n} = useTranslation();


    return (
        <div className="news-detail-similar-news-cards">
            {similarPosts.map((similar) => (
                <div
                    key={similar.id}
                    className="news-detail-similar-news-card"
                >
                    <Link to={(`/${i18n.language}/news/${similar.id}`)}>
                        <div className="news-detail-similar-img">
                            <img src={`${IMAGE_URL_BASE}${similar?.featured_image}`} alt={similar.title}/>
                        </div>
                        <div className="news-detail-similar-news-card-title">
                            <p>{similar.publish_date}</p>
                            <p>
                                {icons[post.category.id] || <NewsIcon/>}
                                {similar.category.name}
                            </p>
                        </div>
                        <h2>{similar.title}</h2>
                        <div className="news-detail-similar-news-card-text">
                            <p>
                                {similar.short_description}
                            </p>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default SimilarNews;
import {Outlet} from "react-router-dom";
import Header from "../header/Header"
import Footer from "../footer/Footer";
import {Spin} from "antd";
import React, {useContext} from "react";
import {UserContext} from "../../context/UserContext";

function Layout() {
    const {isLoading} = useContext(UserContext);
    return (
        <div className="app-wrapper">
            {isLoading && <div className="loading-overlay">
                <Spin/>
            </div>}
                <Header/>
                <div className="content-outlet">
                    <Outlet/>
                </div>
                <Footer/>
        </div>
    );
}

export default Layout;

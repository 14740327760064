import {useEffect} from "react";

export default function Backdrop(ref, setRotate, setIsActive) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setRotate(false);
                setIsActive(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setIsActive, setRotate]);
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "../src/assets/styles/styles.scss"
import "./assets/fonts/HelveticaNeueLTGEO-55Roman.otf";
import "./assets/fonts/HelveticaNeueLTGEO-MT-55Roman.ttf";
import "./assets/fonts/Helvetica-Neue-LT-Com-65-Medium.ttf";
import "./assets/fonts/HelveticaNeueLTGEO-75Bold.otf";
import "./assets/fonts/MS Ring.otf";
import "./locales/i18n"
import UserProvider from "./context/UserContext";
import ModalProvider from "./context/ModalContext";
import SuccessProvider from "./context/SuccessContext";
import CartProvider from "./context/CartContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <UserProvider>
            <ModalProvider>
                <SuccessProvider>
                    <CartProvider>
                        <App/>
                    </CartProvider>
                </SuccessProvider>
            </ModalProvider>
        </UserProvider>
    </React.StrictMode>
);
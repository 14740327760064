import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PaymentFailure = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className="payment">
            <div className="payment-block">
            <h2>{t("paymentFailed")}</h2>
            <Link to={`/${i18n.language}/`}>
                <button>{t("goToMain")}</button>
            </Link>
            </div>
        </div>
    );
};

export default PaymentFailure;

import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Spin} from "antd";

function Redirect() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/ka/');
    }, [])

    return (
        <div className="loading-overlay">
            <Spin/>
        </div>
    );
}

export default Redirect;

import React, {useContext, useState} from 'react';
import {Button, Form, Input, message, Modal, Spin} from "antd";
import api from "../../../service/api";
import {UserContext} from "../../../context/UserContext";
import {useTranslation} from "react-i18next";

function ChangePassword(props) {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const {setIsLoading, isLoading} = useContext(UserContext);
    const [error, setError] = useState({});
    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const handleClose = () => {
        props.close();
        form.resetFields();

    }
    const onFinish = async (values) => {
        try {
            setIsLoading(true);
            const res = await api.put('/customer/update-password', values, {headers});
            handleClose();
            if (res.data.status === 'success') {
                message.success(t("passwordChanged"));
            }
        } catch (e) {
            if (e.response.status === 422) {
                setError(e.response.data);
            }
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) return (
        <div className="loading-overlay">
            <Spin/>
        </div>
    )

    return (
        <Modal className="change-password" open={props.open} onCancel={handleClose} centered
               footer={null}>
            <Form layout="vertical" onFinish={onFinish} form={form}>
                <Form.Item
                    name="current_password"
                    label={t("oldPassword")}
                    validateStatus={error?.errors?.current_password ? ("error") : null}
                    help={error?.errors?.current_password ? error?.errors?.current_password : null}
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        }
                    ]}
                >
                    <Input.Password
                        visibilityToggle={false}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t("newPassword")}
                    validateStatus={error?.errors?.password ? ("error") : null}
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        }
                    ]}
                >
                    <Input.Password
                        visibilityToggle={false}
                    />
                </Form.Item>
                <Form.Item
                    name="password_confirmation"
                    label={t("repeatPassword")}
                    validateStatus={error?.errors?.password ? ("error") : null}
                    help={error?.errors?.password ? error?.errors?.password : null}
                    rules={[
                        {
                            required: true,
                            message: t("fieldRequired"),
                        }
                    ]}
                >
                    <Input.Password
                        visibilityToggle={false}
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit">{t("changePassword")}</Button>
                </Form.Item>

            </Form>
        </Modal>
    );
}

export default ChangePassword;

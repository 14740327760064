import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Arrow} from "../../assets/images/svg/arrow.svg";
import clickOutside from "../backdrop/backdrop";
import {ReactComponent as NewsIcon} from "../../assets/images/svg/news.svg";
import {ReactComponent as EventIcon} from "../../assets/images/svg/calendar-today.svg";
import {ReactComponent as AnnouncementIcon} from "../../assets/images/svg/announcement.svg";
import {ReactComponent as PercentIcon} from "../../assets/images/svg/percent.svg";
import {ReactComponent as All} from "../../assets/images/svg/all.svg";

const icons = {
    5: <All/>,
    1: <NewsIcon/>,
    2: <EventIcon/>,
    3: <AnnouncementIcon/>,
    4: <PercentIcon/>
};

function Dropdown({categories, setActiveTab, activeTab}) {
    const [isActive, setIsActive] = useState(false);
    const [selectedSvg, setSelectedSvg] = useState(0)
    const [rotate, setRotate] = useState(false);
    const wrapperRef = useRef(null);
    const [selected, setSelected] = useState(activeTab);

    useEffect(() => {
        setSelected(activeTab);
    }, [activeTab]);

    const handleClick = ({name, key}) => {
        setActiveTab(key)
        setSelectedSvg(key);
        setSelected(name);
        setRotate(false);
        setIsActive(false);
    }
    const handleClickRotate = () => {
        setRotate(rotate => !rotate);
        setIsActive(!isActive);
    };
    clickOutside(wrapperRef, setIsActive, setRotate);

    return (
        <div className="dropdown" ref={wrapperRef}>
            <div
                className={rotate ? 'dropdown-button-rotate' : 'dropdown-button'}
                onClick={handleClickRotate}
            >
                {categories?.postCategories.map((category) => (
                    (category.key === selectedSvg) &&
                    <span className="dropdown-svg" key={category.id}>
                        {icons[category.id]}
                        {categories.postCategories[selected]?.name}
                    </span>
                ))}
                <span className="dropdown-arrow"><Arrow/></span>
            </div>
            <div className={`dropdown-content ${isActive ? 'show' : null}`}>
                {categories?.postCategories.map((category) => (
                    category.key !== selected &&
                    <div
                        key={category.id}
                        onClick={() => handleClick(category)}
                        className="dropdown-item">
                        <span>{icons[category.id]}</span>
                        {category.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Dropdown;
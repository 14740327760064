import {createContext, useState} from "react";

const defaultContext = {
    isSuccess: false,
    setIsSuccess: () => {
    }
}

export const SuccessContext = createContext(defaultContext);

export const SuccessProvider = ({children}) => {
    const [isSuccess, setIsSuccess] = useState(false);

    return (
        <SuccessContext.Provider value={{isSuccess, setIsSuccess}}>
            {children}
        </SuccessContext.Provider>
    );
};

export default SuccessProvider;
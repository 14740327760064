import React from 'react';
import {Collapse, Empty, Pagination} from "antd";
import ProductList from "./ProductList";
import OrdersList from "./OrdersList";

const {Panel} = Collapse;

function RenderOrderPanels({orders, description, meta, handlePageChange}) {

    return (
        <>
            {orders?.length > 0 ? (
                <Collapse ghost expandIconPosition="end">
                    {orders.map((order) => (
                        <Panel header={<OrdersList order={order}/>} key={order.id}>
                            <ProductList products={order.products}/>
                        </Panel>
                    ))}
                </Collapse>
            ) : (
                <Empty description={description} className="order-empty"/>
            )}
            <div className="orders-pagination">
                <Pagination current={meta?.meta?.current_page} total={meta?.meta?.total}
                            defaultPageSize={10} onChange={handlePageChange} showSizeChanger={false}/>
            </div>
        </>
    );
}

export default RenderOrderPanels;

import {ReactComponent as Subtraction} from "../../../assets/images/svg/subtraction.svg";
import {ReactComponent as Addition} from "../../../assets/images/svg/addition.svg";
import {ReactComponent as Delete} from "../../../assets/images/svg/delete.svg";
import {IMAGE_URL_BASE} from "../../../utils/url.helper";
import useCart from "../../../hooks/useCart";

function CartItem({cart}) {
    const {removeFromCart, updateQuantity} = useCart();
    const handleDeleteQuantity = () => {
        if (cart.quantity - 1 < 1) {
            removeFromCart(cart?.id)
        } else {
            updateQuantity(cart?.id, {quantity: cart.quantity - 1});
        }
    };

    const handleAddQuantity = () => {
        updateQuantity(cart?.id, {quantity: cart.quantity + 1});
    };

    return (
        <div className="header-cart-item">
            <img src={`${IMAGE_URL_BASE}${cart?.product.featured_image}`} alt={cart?.product.name}
                 className="header-cart-image"/>
            <p className='header-cart-title'>{cart?.product.name}</p>
            <div className="header-cart-item-quantity">
                <span onClick={() => handleDeleteQuantity()}><Subtraction/></span>
                <p>{cart?.quantity}</p>
                <span onClick={() => handleAddQuantity()}><Addition/></span>
                <p className="header-cart-price-mobile">{cart?.product.sell_price}₾</p>
            </div>
            <Delete className="header-cart-delete" onClick={() => removeFromCart(cart.id)}/>
            <p className="header-cart-price">{cart?.product.sell_price}₾</p>
        </div>
    );
}

export default CartItem;
import usePages from "../../hooks/usePages";
import React from "react";

function Municipality() {
    const {pageData} = usePages(2);

    return (
        <div className="municipality-container">
            <h1>
                {pageData?.page.title}
            </h1>
            <div className="municipality-text" dangerouslySetInnerHTML={{__html: pageData?.page.content}}/>
        </div>
    )
}

export default Municipality;
